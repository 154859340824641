.btn-outline {
  margin: 2rem 2rem;
  padding: 0.5rem 0.5rem;
  width: 10rem;
  z-index: 10000;
  text-align: center;
  font: normal normal normal 16px/18px Vodafone Rg;
  letter-spacing: 0px;
  color: #e60000;
  opacity: 1;
  border: 1px solid #e60000;
  border-radius: 8px;
  opacity: 1;
}
.btn-outline:hover {
  text-decoration: none;
  color: #e60000;
}
.main-postKeynotes-page-bg {
  background: transparent linear-gradient(113deg, #ffffff 0%, #ffffff39 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 16px;
  opacity: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  height: 75vh;
  width: 100vw;
}
.main-postKeynotes-page-bg-card {
  background: transparent linear-gradient(113deg, #ffffff 0%, #ffffff39 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 16px;
  opacity: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}
.main-postKeynotes-page-header {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 4%;

  border-bottom: 1px solid #d5d5d5;
  opacity: 1;
}
.main-postKeynotes-page-button {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 0.25rem 0.25rem;
  margin-right: 0.25rem;
  width: 8rem;
  margin-bottom: 0.5rem;
  border: none !important;
}
.btn-selcted {
  background: #007c92 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  color: #ebebeb;
  padding: 0.25rem 0.25rem;
  margin-right: 0.25rem;
  width: 8rem;
  margin-bottom: 0.5rem;
  border: none !important;
}
.main-postkeynote-header {
  display: "flex";
  flex-direction: "row";
}
.post-keynote-header {
  padding: 0.5rem 0.5rem;
  position: absolute;
  font: normal normal normal 56px/64px Vodafone Rg;
  letter-spacing: 0px;
  color: #e60000;
  opacity: 1;
}

.carddimensions {
  border: 1px solid #d5d5d5;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  margin: 2rem 0rem 2rem 2.45rem;
  flex-direction: column;
  padding: 0.5rem;
  background-color: white;
  align-items: flex-end;
  max-width: 16.5rem;
}
.sa-card-text {
  text-align: center;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0px;
  color: #6f6f6f;
  opacity: 1;
  display: flex;
}
.small-download-cards {
  position: absolute;
  z-index: 10000;
  cursor: pointer;
}
.t-width {
  margin: 0.5rem 5%;
}
.pagination {
  display: flex;

  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination li,
.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;

  color: #007c92;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #007c92;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
.card-success-panel-main{
      display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 2rem 0;
}