.second-section-div {
    width: 100%;
    padding: 27px 45px 0 45px;
    background-color: black;
min-height: 100%;
   
    
}
.second-page-main-content{
    width: 100%;
display: flex;
    
    flex-direction: column;
  
}
.frame-top-design{
    justify-content: space-between;
    display: flex;
}
.righttopframe{
background-position: right;
    background-image: url("../../assets/framesides/topright.svg");
   background-repeat: no-repeat;
    height: 7rem;
        width: 50%;
}
.lefttopframe{
    width: 50%;
    background-position: left;
        background-image: url("../../assets/framesides/topleft.svg");
    background-repeat: no-repeat;
height: 7rem;
}
.frame-bottom-design {
    justify-content: space-between;
    display: flex;

}

.rightbottomframe {
    background-position: right;
    background-image: url("../../assets/framesides/bottomright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.leftbottomframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/bottomleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}
.second-section-main-title{
        padding-bottom: 49px;
    color: #FFF;
    padding-top: 80px;
    color: #FFF;
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 9.6px;
        text-transform: uppercase;
}
.video-div{
    height: 100%;
    justify-content: center;
    align-items: center;
    /* width: 35%; */
}
.sectiontwo-video{
display: flex;
width: 372px;
    height: 451px;
    flex-shrink: 0;
    margin-right: 20px;
    flex-shrink: 0;
}
.secondsection-description{
  
    color: #FFF;
    
        /* ParReg */
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
       
}
.second-page-main-content-body{
    display: flex;
    
    flex-direction: row;
    align-items: center;
}
.main-div{
    margin-top: 13px;
}
.prizes-title{
    
    color: #FFF;
    padding: 0.5rem 1rem;
        
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
 
            height: 39px;
            flex-shrink: 0;
            background: #e60000;
            position: absolute;
        left: 0rem;
              
                bottom: 34%;
}
.separator{
    position: relative;
        padding: 50.5px 0 35.5px 0;
}
.prizes-text{
    margin: 2rem 0;
    color: #FFF;
    
        /* ParReg */
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1px;
}
.badge-div{
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.one-badge-div{
    display: flex;
    flex-direction: row;
/* padding-top: 73px; */
    align-items: center;
}
.one-badge-img {
width: 56px;
    height: 56px;

}
.one-badge-div-details{
 
   padding-left: 1rem;
}
.one-badge-div-title{
    color: #FFF;
    
        
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
}
.one-badge-div-subtitle{
    color: #FFF;
    max-width: 11rem;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.line{
width: 100%;
    height: 50px;
}
@media only screen and (max-width: 767.98px) {
   

    .second-page-main-content-body{
        flex-direction: column;
        
    }
    .video-div{
        width: auto;
        
    }
                  .sectiontwo-video {
                      display: flex;
                      width: 100%;
                    height: inherit;
                      flex-shrink: 0;
                      margin: 0;
                  }
                                  .main-div {
                                      width: 100%;
                                      margin-left: 0;
                                  }
.secondsection-description,.prizes-text {
justify-content: center;
text-align: start;
display: flex;
color: #FFF;

    /* ParReg */
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
}
.prizes-title {
right: 56.3%;
    top: 3.45rem;
    text-align: center;
    width: 33%;
    font-size: 18px;
}
.line {width: 250px;
    height: 26.5px;width: 20.5rem;}
    .badge-div{
        flex-direction: column;
        align-items: flex-start;
        align-items: center;
        
    }
    .one-badge-div{
padding-bottom: 24px;
    }
        .second-section-main-title {color: #FFF;
        
            text-align: center;
            /* h1Mobile */
            font-size: 22px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.2px;
            text-transform: uppercase;
        padding: 24px 0;}
       .one-badge-div-subtitle{
        max-width: 10rem;
       }
}