.home-nav-size {
  justify-self: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* margin-left: 3rem; */
}
.logo-container {
  position: absolute;
    top: 2.375rem;
    width: 24rem;
    height: 2rem;
    z-index: 2;
    margin-left: 4rem;
  z-index: 2;
  left: 0;
  cursor: pointer !important;
}
.home_logo_size {
  width: 25.625rem;
  height: 2rem;

  opacity: 1;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  background: url('../../assets/hackLogo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 6.25rem;
  top: 2.375rem;
}
.Vois_logo {
  width: 8.063rem;
  height: 2rem;
 background: url('../../assets/_vois_white.png');
  position: absolute;
  left: 6.25rem;
  top: 2.375rem;
}

.home-navitem {
  color: white !important;
  font-size: 20px !important;
  letter-spacing: 0.125rem;
  line-height: 1.438rem;
  position: relative;
  padding: 0px !important;
  margin-right: 40px !important;
}
.home-navitem:last-of-type {
  margin-right: 0px !important;
}
.home-navitem:not(.highlight) > a {
  color: var(--text-primary);
  text-decoration: none;
  background-color: initial;
}
.home-navitem.highlight > a {
  color: var(--text-secondary);
  text-decoration: none;
  background-color: initial;
}
.home-navitem > a:hover,
.home-navitem > a:focus {
  color: var(--text-secondary) !important;
  text-decoration: none;
}

.home-navitem:hover {
  color: var(--text-secondary) !important;
}

.home-navitem:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-secondary);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.home-navitem:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.highlight {
  color: var(--text-secondary) !important ;
  padding-bottom: 0.4rem !important;
}
.highlight:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--text-secondary);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar-toggler-icon {
  background-image: url("../../assets/menu.svg") !important;
  margin-top:0.75rem !important
}

.navbar-toggler {
  border: 1px solid rgba(255, 255, 255);
}
.navbar-home-class {
  padding-top: 2rem !important;
}

.navbar-collapse {
  z-index: 1;
}
#responsive-navbar-nav {
      margin: 0.375rem 6.345rem;
}
/* .nav-space {
  padding: 0rem 4rem 0rem 4rem;
} */
/************animation****************/

@-webkit-keyframes changeBG {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.75;
  }

  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes changeBG {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.75;
  }

  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

/************media queries****************/

@media (min-width: 992px) and (max-width: 1200px) {
  .Vois_logo{
    display: none;
  }
  .home_logo_size {
    width: 0px;
    height: 0px;
    top: 1.813rem;
  }
  .Vois_logo {
    width: 7.75rem;
    height: 1.875rem;
    top: 1.813rem;
  }

}

@media (max-width: 992px) {
  #responsive-navbar-nav {
      margin: 0 !important;
    }
  #whatinit {
      padding: 0 2.25rem !important;
    }
  .logo-container {
    position: absolute;
      top: 2.375rem;
      width: 5rem;
      height: 2rem;
      z-index: 2;
      margin-left: 2rem;
    }
 .logo-container{
  background: url("../../assets/logo.png");
  background-repeat: no-repeat;
  background-size: 100%;
 }
  .navbar {
    position: relative;
  }
  .nav-space {
    padding: 0px;
  }
  #responsive-navbar-nav {
    position: absolute;
    top: 4rem;
    left: 0px;
    width: 100%;
  }
  .home_logo_size {
    width: 0px;
    height: 0px;
    top: 1.031rem;
  }
  .Vois_logo {
    width: 7.75rem;
    height: 1.875rem;
    top: 1.031rem;
    display: none !important;
  }
  .navbar {
    padding-top: 1rem !important;
  }
  .navbar-toggler {
    padding: 0px !important;
    padding-bottom: 1rem !important;
  }
  .navbar-toggler-icon {
    margin-top: 0rem;
  }
  .navbar-nav {
    background: transparent linear-gradient(180deg, #4a4d4e 0%, #0f0f10 100%) 0%
      0% no-repeat padding-box;
    border: 1px solid #ffffff;
    border-radius: 8px;
  }
  .navbar-nav > * {
    padding: 0.5rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    border-bottom: 1px solid var(--text-primary);
    font-size: 1.25rem;
  }
  .navbar-nav > a:first-of-type {
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  .navbar-nav > a:last-of-type {
    border-radius: 0px 0px 0.5rem 0.5rem;
    border-bottom: 0px !important;
  }
  .home-navitem:hover:after {
    transform: scaleX(0);
  }
  .home-navitem {
    margin: 0px !important;
    padding: 0.5rem !important;
  }

  .highlight:after {
    height: 0px;
    width: 0px;
    transform: scaleX(0);
    border: 0px;
  }
}
