.failSubmission-container{
  background-color: rgba(0, 0, 0, 0.959);
  opacity: 1;
  top: 0px;
  left: 0px;
  min-height: 100vh;
}
.failsubmission-page-title{
  text-align: center;
  font: normal normal bold 50px/100px Vodafone Rg;
  letter-spacing: 5px;
  color: #FFFFFF;
  font-size: 3.125rem;
}



/* .maindiv {
    padding: 0 1.5rem;
    overflow: hidden;
    background-image: url("../../../assets/mask.svg");
    background-color: #860101;
    margin: 0 !important ;
    width: 100%;
  }
  .subParentDiv {
    margin-top: 10%;
    color: #4a4d4e;
    border: white 2px solid;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    margin-bottom: 5rem;
    height: 90%;
  }
  .parent {
    margin: 2rem;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 80%;
    color: #4a4d4e;
  }
  .loginBtn {
    background-color: #e60000;
    border: 1px solid #e60000;
    border-radius: 1rem;
    width: 315px;
    height: 48px;
    color: white;
    margin-bottom: 1rem;
  }

.failSubmission-img-size
{
  width: 30%;
  padding-bottom: 2rem;
} */