.nav-main-container{
    flex-direction: row;
        position: absolute;
        z-index: 111;
        padding: 27px 45px 0 47px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.nav-main-container-left{
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.nav-main-container-left-link {
color: #FFF;

    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 24px;
}
.nav-main-container-mid{
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}
.logo{
    background:url("../../assets/vois.svg");
    height: 30px;
        width: 120px;
        background-size: 100% 100%;
        
}
.nav-main-container-right {
    display: flex;
       
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.nav-main-container-right-first{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 24px;
}
.nav-main-container-right-first-text{
    color: #FFF;
        /* font-family: Vodafone; */
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.8px;
}
.nav-main-container-right-first-text2{
    color: #FFF;
    
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.8px;
        text-transform: uppercase;
}
.nav-main-container-right-btn{
    width: 99px;
        height: 32px;
    background: #E60000;
   padding: 0.5rem 1rem;
color: #FFF;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 6px;
}

@media only screen and (max-width: 991.98px) {
    .nav-main-container-right{
        display: none;
    }
        .nav-main-container-left{
            display: none;
        }
                .nav-main-container-mid{
                    width: 100%;
                    align-items: center;
                }
}