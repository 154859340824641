.asc-infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e60000;
  /* border-radius: 4px 4px 0 0; */
  height: 60px;
  width: 100%;

  border-bottom: 2px solid #d3d3d3;
  margin-bottom: 2%;
}
.chat-container-name{
  
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 32px/30px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}
.leftInnerContainer {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}
