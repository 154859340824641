.title-header {
    display: flex;
    padding: var(--4, 4px) var(--8, 8px);
    align-items: center;
    align-content: center;
    gap: 4px var(--4, 4px);
    flex-wrap: wrap;
    font-size: large;
    font-weight: 900;
}

.region-box {
    display: flex;
align-items: center;
justify-content:  center;
    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 8px);
    flex-shrink: 0;
    border-radius: var(--16, 16px);
    background: var(--new-palette-default-monochrome-monochrome-2, #F2F2F2);
    padding: 1rem;
    min-height: 10rem;
    justify-content: center;

}

.region-box-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.regionimg {
    width: 1.5rem;
}

.regionTitle {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px var(--8, 8px);
    align-self: stretch;
    flex-wrap: wrap;
    cursor: pointer;
    font-size: large;
    font-weight: 600;
    justify-content: center;
}

.count {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px var(--8, 8px);
    align-self: stretch;
    flex-wrap: wrap;
    cursor: pointer;
    font-size: large;
    font-weight: 700;
}
.main-container{
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    overflow-y: scroll;
  
}
