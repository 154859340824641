.fourth-section-div {
    width: 100%;
    padding: 0 3rem 0 3rem;
    background-color: black;
    min-height: 100vh;


}

.fourth-page-main-content {
    width: 100%;
    display: flex;

    flex-direction: column;
}

.frame-top-design {
    justify-content: space-between;
    display: flex;
}

.righttopframe {
    background-position: right;
    background-image: url("../../assets/framesides/topright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.lefttopframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/topleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.frame-bottom-design {
    justify-content: space-between;
    display: flex;
}

.rightbottomframe {
    background-position: right;
    background-image: url("../../assets/framesides/bottomright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.leftbottomframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/bottomleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.fourth-section-main-title {
padding-top: 19px;
    padding-bottom: 35px;
  
    color: #FFF;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 9.6px;
    text-transform: uppercase;
}
.vois-logo{
    color: #ffffff;
        
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.2px;
        position: absolute;
        z-index: 1;
    right: 45%;
}
.vois-logo-bold {
    color: #ffffff;

    font-size: 64px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 3.2px;
    position: absolute;
    z-index: 1;
}
/* .nav-pills {
    width: 42%;
} */

.circle {
    width: 7.1rem;
    height: 7.1rem;

    /* UI Properties */

   
    letter-spacing: NaNpx;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    border-radius: 80px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);

    font: normal normal bold 25px/25px Vodafone Rg;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    z-index: 1;
  
}
.circle-img{
    position: absolute;
    
}

.circle-final {
    height: 7.1rem;
    width: 5.1rem;
    /* UI Properties */

    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #3875A6 0% 0% no-repeat padding-box;
    border: 3px solid #FFFFFF;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    letter-spacing: NaNpx;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    border-radius: 80px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);

    font: normal normal bold 25px/25px Vodafone Rg;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.day {
    display: flex;
    justify-content: center;
    

}
.circle-text{
    position: relative;
}
.circle .circle-text>* {
    width: 100%;
    margin-bottom: 0.25rem !important;
}

.circle-text> :first-child {
color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
}

.circle-text> :last-child {
color: #FFF;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
}

.line {
    height: 7rem;
        opacity: 1;
        width: 5%;
        background-repeat: no-repeat;


background-image: url("../../assets/timeline-line.svg");
  
}

.step-text {

    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
        color: white;
}

.step-desc {
    color: #FFF;
    margin-top: 5px;
        /* Sub-Head */
        
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.9px;
}
.desc-list{
    padding: 0 17px;
}
.step-desc-container {
    height: 7.1rem;
    justify-content: center;
    text-align: left;
}

.section5-redline {
    justify-self: center;
    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #e60000 0% 0% no-repeat padding-box;
    border-radius: 8px;
    width: 11.938rem;
    height: 3px;
}

.entry-header {
    margin-bottom: 1.1rem;
}
        
        .video-div{
            /* border: #e60000 5px solid;
                background-color: #FFF; */
                display: flex;
                justify-content: center;
                align-items: center;
        }
        .sectiontwo-video{
            transform: rotate(90deg);
            height: 50%;
                object-fit: cover;
        }
/* .video-div video{
   
        width: 100%;
            
            position: relative;
            object-fit: cover;
            z-index: 0;
transform: rotate(90deg);
border: #e60000 1px solid;

} */
.step-container {
    display: flex;
    justify-content: center;
    margin: 0px;
    width: 100%;
    position: relative;
}
/* .video-div{
    position: absolute;
width: 100%;
    right: 37.5%;
        margin-top: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
} */

@media only screen and (max-width: 767.98px) {
    .fourth-section-main-title{
        color: #FFF;
        
            text-align: center;
            /* h1Mobile */
            font-size: 22px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.2px;
            text-transform: uppercase;
    }
    .video-div{display: none;}
    .nav-pills {
            width: 100%;
                padding: 0 1.25rem;
        }
    .step-container {
        margin-left: 0 !important;
        padding: 2rem 0 !important;
    }

    header.entry-header {
        color: white;
        letter-spacing: 2.8px;
        line-height: 3rem;
        font-size: 28px;
    }

    .step-text {
        color: #FFF;
            word-break: break-word;
            /* h3Mobile */
            font-size: 20px;
            font-style: normal;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 1px;
            text-transform: uppercase;
       
    }

    .circle,
    .circle-final,
    .step-desc-container {
        width: 4.3rem;
        height: 4.3rem;
    }

    .circle-text {
        width: 3rem;
        height: 3.4rem;
            display: flex;
                flex-direction: column;
                justify-content: center;
    }

    .circle .circle-text>* {
        margin-bottom: 0px !important;
    }

    .circle-text> :first-child {
color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
    }

    .circle-text> :last-child,
    .step-desc {
        color: #FFF;
        
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: 0.7px;
            word-break: break-word;
           
    }

    .step-desc-container {
        width: initial;
padding: 0 0 0 1rem !important;
        line-height: 3rem;
            word-break: break-all;
    }

    .line {
        background-image:url("../../assets/timeline-line-mobile.svg");
        background-repeat: no-repeat;
        height: 54px;
            width: 10%;
            margin: 0.75rem 0;
    }
  
            .videos{
         display: none;
    
            }
}