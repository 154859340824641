.form {
  display: flex;
background: #000000 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
}

.asc-input {
  width: 100%;
  height: 80px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 16px;
  opacity: 1;
}
.asc-send-btn {
  position: absolute;
  bottom: 5%;
  right: 2%;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg) !important;
  letter-spacing: var(--unnamed-character-spacing-2-4)  !important;
  color: var(--unnamed-color-ffffff)  !important;
  text-align: center  !important;
  font: normal normal normal 24px/30px Vodafone Rg  !important;
  letter-spacing: 2.4px  !important;
  color: #FFFFFF  !important;
  text-transform: uppercase  !important;
  opacity: 1;
  background: #000000 0% 0% no-repeat padding-box  !important;
  border-radius: 8px  !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.sendButton {
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  width: 20%;
}
