.breakroomLising-pageContainer{
    background-color: rgba(0, 0, 0, 0.959);
}
.breakroom-separating-line{
    border-right: 1px solid #FFFFFF;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.breakroom-title{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
        letter-spacing: var(--unnamed-character-spacing-3-2);
        color: var(--unnamed-color-ffffff);
        text-align: right;
        font: normal normal bold 32px/30px Vodafone Rg;
        letter-spacing: 3.2px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        margin: 2rem 0;
}
.breakroom-img{
    width: 246px;
        max-height: 166px !important;
}