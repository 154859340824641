.main-component{
position: fixed;
    bottom: 3rem;
    left: 6rem;
    
    min-height: 15%;
        width: 35%;
    border: 3px solid var(--unnamed-color-ffffff);
        background: transparent linear-gradient(180deg, #000000 0%, #4B4B4B 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 3px solid #FFFFFF;
        border-radius: 16px;
        opacity: 1;
        z-index: 10000000;
     
        padding: 1rem 1rem;
       
}
.submain-component{
background-image: url('../../assets/cookies.svg');
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}
.submain2-component{
    background-position-x: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}
.cookies-header{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
        letter-spacing: var(--unnamed-character-spacing-4);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal bold 40px/40px Vodafone Rg;
        letter-spacing: 4px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        padding-bottom: 1rem;
}
.cookies-body{
    letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
}
.cookies-btn-div{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.cookies-btn-subdiv {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.cookie-Manage-all-btn{
    cursor: pointer;
        padding: 0.5rem 0;
        margin: 0rem 0.5rem;
        width: 100%;
        background-color: transparent;
        border: #FFFFFF 2px solid;
        border-radius: 10px;
        opacity: 1;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
        letter-spacing: var(--unnamed-character-spacing-2-4);
        text-align: center;
        font: normal normal normal 24px/30px;
        letter-spacing: 2.4px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
}
.cookie-reject-all-btn{
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    margin: 0rem 0.5rem 0 0;
    height: auto;
   width: 50%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
            letter-spacing: var(--unnamed-character-spacing-2-4);
            text-align: center;
            font: normal normal normal 24px/30px;
            letter-spacing: 2.4px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
}
.cookie-accept-all-btn {
    cursor: pointer;
    padding: 0.5rem 0;
    width: 40%;
    background: var(--unnamed-color-A70502) 0% 0% no-repeat padding-box;
    background: #A70502 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    text-align: center;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
@media (max-width: 992px) {
    .main-component{
        bottom: 1rem;
            left: 0;
            width: 100%;
    }
}