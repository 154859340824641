.required {
  color: #e60000 !important;
}

.reg-alert {
  background-color: unset !important;
  color: #B3261E !important;
  border: unset !important;
  padding: unset !important;
      max-height: 3rem;
}




.field-icon {
  /* float: right; */
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.btn-verify-send-verefied {
  border: 1px solid #007c92;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: white;
  background-color: #007c92;
  width: 79px;
  height: 40px;
  cursor: pointer;
  margin-top: 2.5rem;
}

.btn-verify {
  border: 1px solid #007c92;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  margin: 2.3rem auto;
}

.verify-form {
  margin-left: 0.1rem !important;
}

.btn-position {
  position: relative;
  z-index: 1;
}

.verify {
  width: 100%;
}

.email-row {
  height: 6rem;
}

.phone-row {
  height: 6rem;
  margin-top: 4.5rem;
}

.btn-verify-send {
  border: 1px solid #007c92;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  cursor: pointer;
  margin-top: 2.5rem;
}

.btn-phone-position {
  position: relative;
  z-index: 1;
}

.btn-phone-verify {
  border: 1px solid #007c92;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  margin-top: 0.1rem;
}

.phone-verify {
  position: relative;
  bottom: 3.5rem;
}

.image-res-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: unset;
}

.file-res-input-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.file-res-input-container-text {
  color: #e60000;
  -webkit-margin-start: 1rem;
  margin-inline-start: 1rem;
}

.file-name {
  color: #4a4d4e;
}

.change-file-btn-text {
  color: #e60000;
  text-decoration: underline;
}

.form-check1-input {
  width: 15px;
  height: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccd;
  border-radius: 10px;
  opacity: 1;
}

.form-check1-label {
  text-align: left;
  font: normal normal normal 18px/18px Vodafone Lt;
  letter-spacing: 0px;
  color: #4a4d4e;
  position: absolute;
}

.register-page-container {
  margin: 3rem 3rem 0rem 4rem !important;
  min-height: calc(100vh - 10rem);
}

.registration-stepper-container {
  margin-left: 2rem;
}

.input-width-80 {
  width: 80%;
}

.input-width-95 {
  width: 95%;
}

.input-width-100 {
  width: 100%;
}

.user-details-container {
  width: 100%;
  margin: 0px !important;
  min-height: calc(100vh - 10rem);
}

/*align form container with nav */
.user-details-alignment {
  padding: 2rem !important;
  border-radius: 10px;
  border: 3px #f8f9fa solid;


}

.scrolled::-webkit-scrollbar-thumb {
  background: darkgray;
}

.scrolled::-webkit-scrollbar {
  width: 0.5rem !important;
  background-color: #191818 !important;
  border-radius: 10px;
}

.main-user-form-body-container::-webkit-scrollbar {
  width: 2px !important;
  background-color: darkgrey !important;
}

.user-details-container::-webkit-scrollbar {
  width: 2px !important;
  background-color: darkgrey !important;
}

.user-details-container .scrolled {
  height: 75vh;
}


@media (max-width: 767.98px) {
  .verify-mobile-div {
    margin-top: 1rem;

  }

  .verify-div {
    margin-top: 1rem;
    padding-left: 0 !important;
  }

  .btn-next-container {
    text-align: right;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 0;
    float: right;
  }

  .next-btn-disabled {
    float: right;
  }

  .user-details-container {
    padding: 0px;
    margin: 0px;
  }

  .hide-stepper-container {
    display: none;
  }

  .form-group {
    padding-right: 1rem !important;
  }
}

@media (max-width: 991.98px) {


  .user-details-container {
    padding: unset !important;
    margin: unset !important;
  }

  .user-details-container .scrolled {
    padding: 18px !important;
  }

  .user-details-alignment {
    padding: 0rem !important;
  }
}

.btn-next-container {

  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: 0.5rem;

  padding-bottom: 1rem;
}

.next-btn-egypt {
  width: 183px;
  height: 48px;
  border: solid 1px white;
  border-radius: 10px;
  background-color: unset;
  background-color: white;
  color: #e60000;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.verify-btn {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/23px var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0.45rem 0.9rem;
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  cursor: pointer;
  background-color: transparent;
}

.verify-btn-disabled {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/23px var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  padding: 0.45rem 0.9rem;
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  cursor: not-allowed;
}

.verify-div {
  align-items: flex-end;
  display: flex;
}

.verify-mobile-div {
  align-items: flex-end;
  display: flex;

}