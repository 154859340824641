.sixth-section-div {
    width: 100%;
    padding: 2.45555rem 3rem 65px 3rem;
    background-color: black;
    


}
.single-div{
background-size: 100%;
        background-repeat: no-repeat;
 
        
        /* margin-bottom: 2rem !important; */
   
    background-image: url("../../assets/section-frame.svg");
    /* height: auto;
     */
width: 29vw;
    height: 63.5vh;
        /* margin-right: 1rem; */
    
}
.single-div{

}
.card-lines{
    position: relative;
    width: 120.789px;
        height: 17.19px;
    left: 75%;
}
.title{
    color: #FFF;
    text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 106px;
            height: 39px;
            flex-shrink: 0;
            background: #E60000;;
            display: flex;
                align-items: center;
                justify-content: center;
                   
}
.div-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.card-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
}
.card-content-title{color: #FFF;
    font-size: 2.107vw;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    min-height: 10.5vh;
    margin-bottom: 1.5vh;

}
.card-content-body {color: #FFF;

    /* ParLight */
    
font-size: 1.448vw;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1px;}
           @media only screen and (max-width: 767.98px) {
            .div-container {flex-direction: column;}
                                                .single-div {
                        
                                                    background-repeat: no-repeat;
                                                        background-size: contain;
                                                        width: 100%;
                                                        min-height: 35vh;
                                                        margin-top: 6vh !important;
                                                        max-height: 43vh;
                        
                                                    background-image: url("../../assets/section-frame.svg");
                                             
                        
                                                }
                                                .title{
                                                    color: #FFF;
                                                    
                                                        font-size: 20px;
                                                        font-style: normal;
                                                        font-weight: 300;
                                                        line-height: normal;
                                                }
                                                .card-content-title{
                                                    font-size: 4vw;
                                                    letter-spacing: 1.2px;
                                                    min-height: 6.5vh;
                                                    margin-bottom: 0;
                                                }
                                                .card-content-body{
font-size: 3.6vw;
}
                                                }
                                                                                                        
