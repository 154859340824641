.toggle-sound {
    position: absolute;
    top: 5%;
    right: 4rem;
    /* background-color: black; */
    /* width: 5%; */

    text-align: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 #f06292;
}

.toggle-Next {
    position: absolute;
    top: 5%;
    left: 4rem;
    text-align: center;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 #f06292;
        transform: rotate(180deg);
}

.Next--icon {
    color: white;
    line-height: inherit;
    font-size: 2rem;
    display: block;
    margin: auto;
    text-align: center;
    margin-left: 1rem;

    margin-top: -2%;
    top: 0;
}

.toggle-sound.sound-mute {
    box-shadow: none;
}

@-webkit-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

@-ms-keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

@keyframes pulse {
    to {
        box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
}

.sound {
    width: 97%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    display: inline-block;
    left: 0;
    top: -3;
    margin-left: -15%;
}

.sound--icon {
    color: inherit;
    line-height: inherit;
    font-size: 1.6rem;
    display: block;
    margin: auto;
    text-align: left;

    margin-top: -15%;
    top: 0;
}

@media screen and (min-width: 581px) {

    .sound--icon,
    .Next--icon {
        padding-left: 25%;
    }
}

.sound--wave {
    position: absolute;
    border: 2px solid transparent;
    border-right: 2px solid #fff;
    border-radius: 50%;
    transition: all 200ms;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.sound--wave_one {
    width: 45%;
    height: 40%;
}

.sound--wave_two {
    width: 70%;
    height: 62%;
}

.sound--wave_three {
    width: 95%;
    height: 75%;
}

.sound-mute .sound--wave {
    border-radius: 0;
    width: 35%;
    height: 35%;
    border-width: 0 2px 0 0;
    left: 5px;
}

.sound-mute .sound--wave_one {
    -webkit-transform: rotate(45deg) translate3d(0, -50%, 0);
    transform: rotate(45deg) translate3d(0, -50%, 0);
}

.sound-mute .sound--wave_two {
    -webkit-transform: rotate(-45deg) translate3d(0, 50%, 0);
    transform: rotate(-45deg) translate3d(0, 50%, 0);
}

.sound-mute .sound--wave_three {
    opacity: 0;
    transform: translateX(-46%);
    height: 20%;
}

.fullScreenBackground{
    background-color: black;
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    
}
.videocreenheader{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
   
}
.vois-logo{
    margin: 1rem 0 0 0;
    width: 83.824px;
        height: 20.902px;
}
.hack {
width: 193px;
    height: 25px;
    margin: 1rem 0 1rem 0;
}
.video-frame-div{

    width: 100%;
    height: 100%;
    justify-content: center;
  
    display: flex;
}
.video-frame{
height:70%;
width:100%;
position: absolute;
z-index: 1;
display: flex;
pointer-events: none;
}
.ifram-styling1{

position: relative;
    right: 3.75%;
    top: 7.5%;
   
height: 62vh;
    width: 55.5vw;
}
.ifram-styling2 {
position: relative;
    right: 2.75%;
    top: 7.5%;

    height: 62vh;
    width: 55.5vw;
}
.ifram-styling3 {
    position: relative;
    right: 2.75%;
    top: 7.5%;

    height: 62vh;
    width: 55.5vw;
}
.ifram-styling4 {
    position: relative;
    right: 1.25%;
    top: 7.5%;

    height: 62vh;
    width: 55.5vw;
}
.ifram-styling5 {
    position: relative;
    right: 3.75%;
    top: 7.5%;

    height: 62vh;
    width: 55.5vw;
}
.ifram-styling6 {
    position: relative;
    right: 2.25%;
    top: 2.5%;

    height: 68vh;
    width: 65.5vw;
}
.card-name{
    color: #FFF;
    position: relative;
    left: 3.5%;
    top:0.5%;
    z-index: 2;
        text-align: center;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
}
.card-name6{
    color: #FFF;
        position: relative;
        left: 8.5%;
        top: 0.5%;
        z-index: 2;
        text-align: center;
        font-size: 1vw;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
}


.footer-main-div {
    display: flex;
    justify-content: center;
    position: relative;
  width: 58vw;
  /* border:#FFF 1px solid ; */
    align-items: center;
    flex-direction: column;
    /* height: 5rem; */
    z-index: 100000000;
   padding-bottom: 2vh;

}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0.5rem;
    margin-top: 1rem;
}

.left-section {
    display: flex;
    align-items: center;
    justify-content: space-between;


}

.right-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.left-section-content {
    color: #FFF;

    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.6px;
    margin-right: 1.5vw;
}

.right-section-content {
    width: 23.998px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
}

.line {
    padding-top: 3rem;
    cursor: pointer;
}

.line-img {
    display: none;
}