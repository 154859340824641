.asc-messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.asc-messageContainer {
  display: flex;

  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 1.5rem;
}

.asc-sentText {
  max-width: 10rem;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 16px;
  opacity: 1;
  margin: -1.25rem 2rem;
  padding: 0.5rem 0.5rem;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.asc-justifyStart {
  justify-content: flex-start;
  flex-direction: column;
}

.justifyEnd {
  justify-content: flex-end;
}

.asc-colorWhite {
  color: white;
}

.asc-colorDark {
  color: white;
}

.asc-backgroundBlue {
  max-width: 75%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 16px;
  opacity: 1;
  color: black;
  align-items: flex-end;
  display: flex;
}

.asc-backgroundLight {
  max-width: 75%;
  height: 100%;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  background-color: "#e60000";
}
