.borderBottom {
  border-bottom: 0.1rem solid #cccccc !important;
}
.Container-NavBar {
  overflow: hidden;
}
.redBg {
  /* background-color: rgba(0, 0, 0, 0.959) !important; */
  color: #ffffff !important;
  letter-spacing: 0px;
  opacity: 1;
}
.whiteBg .nav-link {
  background-color: #ffffff !important;
  color: #4a4d4e !important;
  letter-spacing: 0px;
  opacity: 1;
}

.white {
  color: #ffffff !important;
}
.white-1 {
  color: #cccccc !important;
}
.blue {
  color: #007c92 !important;
}
.red-1 {
  color: #e60000 !important;
}
.grey-1 {
  color: #999999 !important;
}
.grey-2 {
  color: #707070 !important;
}
.grey-3 {
  color: #4a4d4e !important;
}
.modal-generate-link {
  border-radius: 16px;
}
.generate-link-modal-btn {
  background-color: #e60000;
  color: white;
  border-radius: 16px;
  width: 100%;
}
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.nav-dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content-globe{
  display: none;
  position: fixed;
  right: 0;
  background-color: #4a4d4e;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  width: 20px;
  /* float: right; */
  right: 10%;
}
.dropdown-content {
  display: none;
  position: fixed;
  right: 0;
  background-color: #4a4d4e;
  min-width: 12.5rem;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  width: 25px;
  /* float: right; */
  right: 6.5%;
}

.dropdown-content a,.dropdown-content-globe a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
 
}
.dropdown-content a:hover,.dropdown-content-globe a:hover {
  cursor: pointer;
  background-color: black;
}

.nav-dropdown:hover .dropdown-content,.nav-dropdown:hover .dropdown-content-globe {
  display: block;
}

.nav-dropdown:hover .dropbtn {
  background-color: transparent;
}
.bell-icon-badge{
  position: relative;
  top: -15px;
  right: 10px;
  font-size: 14px !important;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}