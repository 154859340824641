/**************prize-card-style***************/
.prizes-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  text-align: center;
  
}
.prizes-card *{
    color:var(--text-primary);
  }
  
  .prizes-card-border{
    border-left: 2px solid var(--text-primary);
    border-right: 2px solid var(--text-primary) ;
  }
  
  .prizes-card--vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
    text-align: center;
    
  }
  
  .prizes-card--vertical  .prize-card__image {
    margin-bottom: 1rem;
    width: 5rem;
  }
  
  .prizes-card--vertical .prizes-card__ranking {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-24) /
      var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--text-secondary);
  
  }
  .prizes-card--vertical .prizes-card__name {
    letter-spacing: var(--unnamed-character-spacing-4);
    font: normal normal normal 2.5rem Vodafone Rg;
    letter-spacing: 4px;
    margin: 0px;
    text-transform: uppercase;
    opacity: 1;
   
  }
  
  
  
  .card-success-panel-headertext {
    color: var(--unnamed-color-4a4d4e);
    text-align: left;
    font: normal normal normal 32px/37px Vodafone Rg;
    letter-spacing: 0px;
    color: #4a4d4e;
    opacity: 1;
  }
  .card-success-panel-innertext {
    color: var(--unnamed-color-e60000);
    text-align: left;
    font: normal normal normal 24px/27px Vodafone Rg;
    letter-spacing: 0px;
    color: #e60000;
    opacity: 1;
  }
  
  /***********************/
 
  .prizes-card--horizontal{
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    
  }
  .prizes-card--horizontal *{
   color: var(--dark-gray);
  }
  
  .prizes-card--horizontal  .prize-card__image {
    margin-bottom: 1rem;
    width: 5rem;
  }
  
  .prizes-card--horizontal .prizes-card__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1.25rem;
    text-align: left;
  }
  
  .prizes-card--horizontal .prizes-card__ranking {
    font: var(--unnamed-font-style-normal) normal
      var(--unnamed-font-weight-normal) var(--unnamed-font-size-24) /
      var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--text-secondary);
  
  }
  .prizes-card--horizontal .prizes-card__name {
    letter-spacing: var(--unnamed-character-spacing-4);
    font: normal normal normal 2.5rem Vodafone Rg;
    letter-spacing: 4px;
    margin: 0px;
    text-transform: uppercase;
    opacity: 1;
  }
  
  
  .card-success-panel-text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-success-panel-headertext {
    color: var(--unnamed-color-4a4d4e);
    text-align: left;
    font: normal normal normal 32px/37px Vodafone Rg;
    letter-spacing: 0px;
    color: #4a4d4e;
    opacity: 1;
  }
  .card-success-panel-innertext {
    color: var(--unnamed-color-e60000);
    text-align: left;
    font: normal normal normal 24px/27px Vodafone Rg;
    letter-spacing: 0px;
    color: #e60000;
    opacity: 1;
  }
  
  
  
  /***************scales*************/
  /*scale one*/
  .prizes-card--scale-one .prizes-card__image{
    width: 7.2rem;
    height: 9.2rem;
    margin-bottom: 1rem;
  }
  .prizes-card--scale-one .prizes-card__ranking{
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
    letter-spacing: .17rem;
    
  }
  .prizes-card--scale-one .prizes-card__name{
    font-size: 2.8rem;
    line-height: 2.8rem;
    letter-spacing: .28rem
  }
  
  /*scale two*/
  .prizes-card--scale-two .prizes-card__image{
    width: 5rem;
    height: 6.5rem;
    margin-bottom: .7rem;
  }
  .prizes-card--scale-two .prizes-card__ranking{
    font-size:1.182rem;
    line-height: 1.182rem;
    margin-bottom: .7rem;
    letter-spacing: .118rem;
    padding-bottom: .7rem ;
  }
  .prizes-card--scale-two .prizes-card__name{
    font-size: 2rem;
    line-height:2rem;
    letter-spacing: .2rem;
  }