.slick-ss {
    overflow-x: hidden;
    width: 99vw !important;
}

.red-line {
    width: 100%;
    position: relative;
top: 29vh;
    transform: rotate(-5.588deg);
    flex-shrink: 0;
    background: #E60000;
    z-index: 100000;

}
.seventh-section-div{
    width: 100%;
     
        background-color: black;
        background-image: url("../../assets/finalsectionbg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 54rem;
        /* margin-bottom: 2rem; */
}
.sloganone-div {
    background-position: center;
    background-image: url("../../assets/Group 1168.svg");
    height: 2rem;
    max-width: 15rem !important;
    background-size: 100% 100%;
}
.second-section-div {
    width: 100%;
    padding: 2rem 3rem 0 3rem;
    background-color: black;
    min-height: 100%;


}
.separator-lines{
    position: absolute;
    right: 0;
    top: 2.7rem;
    width: 120.789px;
        height: 17.19px;
}

.seventh-page-main-content {
    width: 100%;
    display: flex;

    flex-direction: column;
    position: relative;
top: 35vh;
}

.frame-top-design {
    justify-content: space-between;
    display: flex;
}

.righttopframe {
    background-position: right;
    background-image: url("../../assets/framesides/topright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.lefttopframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/topleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.frame-bottom-design {
    justify-content: space-between;
    display: flex;

}

.rightbottomframe {
    background-position: right;
    background-image: url("../../assets/framesides/bottomright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.leftbottomframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/bottomleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.seventh-section-main-title {
color: #FFF;
    text-align: right;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    transform: rotate(-90deg);
    position: absolute;
    right: -20%;
    bottom: 10vw;
    padding: 0;

}

.video-div {
    height: 100%;
    justify-content: center;
    align-items: center;
        padding-right: 12px;
}

.sectiontwo-video {
    display: flex;
width: 399px;
    height: 266px;
    margin-top: 2rem;
    flex-shrink: 0;
}

.secondsection-description {

    color: #FFF;

    /* ParReg */
    
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

}

.seventh-page-main-content-body {
    display: flex;
    align-items: center;

    flex-direction: row;
    padding: 0 2rem;
}

.main-div {
    width: 55%;
   
  
}
.separator-title{
position: absolute;
right: -19.7%;
    top: 4.75rem;
   
}

.prizes-title {

    position: absolute;
        left: 0.3%;
        
        color: #FFF;
        
        font-size: 32px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        width: 100%;
}

.separator {
    position: relative;
        padding: 0 0 24px 0;
}

.prizes-text {
    
    color: #FFF;

    /* ParReg */
    
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}

.badge-div {
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.one-badge-div {
    display: flex;
    flex-direction: row;

}

.one-badge-div-details {
    padding-top: 2rem;
    padding-left: 1rem;
}

.one-badge-div-title {
    color: #FFF;

    
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.one-badge-div-subtitle {
    color: #FFF;

    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.footer-line {
    width: 80%;
 
}
.footer-main-div{
    display: flex;
    justify-content: center;
    position: relative;
    top: 45%;
    align-items: center;
    flex-direction: column;
    height: 5rem;
    z-index: 100000000;
    

}.footer-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
        padding: 0px 0.5rem;
        margin-top: 1rem;
}
.left-section{
    display: flex;
    align-items: center;
    justify-content: space-between;


}
.right-section{
    display: flex;
        align-items: center;
        justify-content: space-between;
     
}
.left-section-content{
    color: #FFF;
    
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1.6px;
            margin-right: 24px;
}
.right-section-content {
    width: 23.998px;
        height: 24px;
        margin-left: 16px;
        cursor: pointer;
}
.line{
        padding-top: 3rem;
        cursor: pointer;
}
.line-img{
    display: none;
}
@media only screen and (max-width: 767.98px) {
    .red-line{
        top: 0;
    }
.line-img{
    padding-top: 2rem;
        width: 100%;
}
.seventh-section-main-title {color: #8F1818;
    text-align: right;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 4.83px;
    text-transform: uppercase;
    position: inherit;
    transform: none;
    text-align: left;
    padding-top: 2rem;}
.seventh-page-main-content{
    top: 0;
}
.seventh-page-main-content-body {display: flex;
    flex-direction: column;
    top: 0;
    }
        .seventh-section-div {max-height: 129vh;height: 60rem;}
    .second-page-main-content-body {
        flex-direction: column;
    }

    .video-div {
        width: auto;

    }

    .sectiontwo-video {
        display: flex;
        width: 100%;
        flex-shrink: 0;
        width: 253px;
            height: 169px;
            margin-bottom: 1rem;
    }

    .main-div {
        width: 100%;
        margin-left: 0;
    }

    .secondsection-description,
    .prizes-text {
        color: #FFF;
        
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1px;
    }

    .prizes-title {
        display: flex;
            align-self: center;
            justify-self: center;
            text-align: center;
            color: #FFF;
            
                font-size: 21px;
                font-style: normal;
                font-weight: 350;
                line-height: normal;
                letter-spacing: 1.05px;
                text-transform: uppercase;
          
    }

   
    .badge-div {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 15%;
    }
    .footer-main-div{
        top: 1%;
            padding: 0 2rem;
    }
    .footer-content{
        width: 100%;
        flex-direction: column;
    }
    .left-section-content{
        margin-right: 3vw;
    }
        .right-section-content {margin-left: 1.5vw;}
        .footer-line{
            width: 100%;
        }
         .separator {
             position: relative;
             padding: 24px 0 24px 0;
         }
}