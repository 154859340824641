.add-group-text-box {
  border-radius: 1rem !important;
  height: 2.5rem !important;
  border: 0.1rem solid #bebebe !important;
}
.add-groups-modal-body {
  background: rgba(0, 0, 0, 0.959) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  color: white;
}
.add-groups-modal-save-btn {
  background: none !important;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  /* width: 100%; */
  font-size: 1.5rem;
  letter-spacing: .15rem;
  opacity: 1;
  width: 80%;
  padding: 0.5rem; 
  justify-content: center;
    display: flex;
    align-items: center;
}

.close{
  color: white !important;
}

.options{
  background-color: white;
  color: black;
}

.modal-dialog {
  margin-top: 5% !important;
}
.adding-project{
  display:flex;
  flex-direction: row;
  justify-content: space-between;

}
.add-icon{
  font: bold;
  cursor: pointer;
}
.picker,.react-datetime-picker__calendar-button{
  background-color: white !important;
}
.picker .react-datetime-picker__wrapper {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.picker .react-datetime-picker__inputGroup {
  font-size: 16px;
}

.picker .react-datetime-picker__clear-button,
.picker .react-datetime-picker__calendar-button {
  color: #007BFF;
}
.react-datetime-picker__inputGroup__input{
color: black;
}