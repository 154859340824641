.borderBottom {
  border-bottom: 0.1rem solid #cccccc !important;
}

.white {
  color: #ffffff !important;
}
.white-1 {
  color: #cccccc !important;
}
.blue {
  color: #007c92 !important;
}
.red-1 {
  color: #e60000 !important;
}
.grey-1 {
  color: #999999 !important;
}
.grey-2 {
  color: #707070 !important;
}
.grey-3 {
  color: #4a4d4e !important;
}
.inner-nav-back-text {
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/28px Vodafone Rg;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
