.videocreenheader {
    display: flex;
    flex-direction: row;

    width: 100%;
}

.logos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.vois-logo {
    margin: 1rem 0 0 0;
    width: 83.824px;
    height: 20.902px;
}

.hack {
    width: 193px;
    height: 25px;
    margin: 1rem 0 1rem 0;
}
.player{
    width: 80vw;
    height: 50vh;
}
.back-div {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.back-txt {
    margin-left: 0.5rem;
    color: #FFF;

    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.1px;
}
.agenda-list{
    z-index: 10;
        width: 80%;
        
            background: #E60000;
    
        border: none;
        padding: 0;
            margin: 0;
            min-height: 65vh;
            max-height: 70vh;
            overflow: auto;
            top:10%;
            
}
 .agenda-item:nth-child(even) {
     background: #D60101;
     min-height: 77px;
     display: flex;
     flex-direction: row;
     width: 100%;
     align-items: center;
 }

 .agenda-item:nth-child(odd) {
     background: #E60000;

     display: flex;
     flex-direction: row;
     min-height: 77px;
     width: 100%;
     align-items: center;
 }

 .time {
     padding: 1rem;
     width: 5rem;
     color: #FFF;

     text-align: center;
     font-size: 16px;
     font-style: normal;
     font-weight: 700;
     line-height: normal;
     border-right: 1px white solid;
 }

 .speaker-div {
     display: flex;
     flex-direction: column;
     padding-left: 1rem;
     width: 60%;
 }

 .speaker {
     color: #FFF;

     font-size: 16px;
     font-style: normal;
     font-weight: 300;
     line-height: normal;
 }

 .title {
     color: #FFF;

     font-size: 20px;
     font-style: normal;
     font-weight: 400;
     line-height: normal;
 }
 .agenda-title{
    color: #FFF;
    
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
 }
 .qa-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 77px;
        width: 80%;
        border-radius: 5px 5px 0px 0px;
            background: #D60101;
            padding: 0 1rem;
 }
 .date-in-agenda{
color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-decoration-line: underline; */
    padding: 0.5rem;
    border: white 1px solid;
    border-radius: 6px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
 }
 .agenda-side{
top: 17%;
    position: absolute;
    left: -0.5%;
    display: flex;
    flex-direction: column;
 }
 .arrow-down{
    margin-left: 0.5rem;
     
 }
 .drop-down-item{
    font-size: 16px;
        line-height: 28px;
        display: block;
        padding-left: 1rem;
        cursor: pointer;
        padding: 0.5rem 1rem;
 }
 .drop-down-item:hover{
background-color: #E60000;
color: #FFF !important;


 }