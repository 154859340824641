.modal-section-header{
    font-weight: bold;
    margin:1rem 0;
    color: white;
}
.modal-section-body {color: white;}
.modal-body{
    overflow: auto;
    max-height: 25rem;
    display: flex;
    flex-direction: column;
        width: 100%;
}
.modal-bg{
background-color: black;
}
.accordion-div{
    margin: 1rem 1rem 1rem 0;
}
.cookie-btn {
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    margin: 0rem 0.5rem 1rem 0;
    height: auto;
    width: 17%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    text-align: center;
    font: normal normal normal 24px/30px;
    letter-spacing: 2.4px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}
.cookie2-btn {
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    margin: 0rem 0.5rem 1rem 0;
    height: auto;
    width: 35%;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    text-align: center;
    font: normal normal normal 24px/30px;
    letter-spacing: 2.4px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
}
@media only screen and (max-width: 991.98px) {
    .cookie-btn {width: 50%};
        .cookie2-btn {width: 100%;}
}