.judgementPanel-wrapper{
  background-color: rgba(0, 0, 0, 0.959);
  background-image: url("../../assets/numbersbg.png");
}

.judges-main-screen-card {
  height: fit-content;
  background: rgba(0, 0, 0, 0.959) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  border: 2px solid white;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 20rem;
}
.judgement-main-screan-card-header {
  padding: 0 1rem 0 1rem;
  text-align: center;
  font: normal normal normal 38px/55px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.judgement-main-screan-card-subheader {
  color: var(--unnamed-color-4a4d4e);
  text-align: center;
  font: normal normal normal 24px/28px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.judgement-main-screan-card-joinMeetingbtn {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: .15rem;
}
.judgement-main-screan-card-joinMeetingbtn-disabled {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: .15rem;
}
.judgement-main-screan-card-joinEvaluatebtn {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: .15rem;
}

.switch2 {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 36px;
}

/* Hide default HTML checkbox */
.switch2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: #ccc; */
  -webkit-transition: .4s;
  transition: .4s;
}

.slider2:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider2 {
  background-color: transparent;
  border: 1px white solid;
  
}

input:focus+.slider2 {
  background-color: transparent;
  border: 1px white solid;
}

input:checked+.slider2:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider2.round {
  border-radius: 34px;
    border: 1px white solid;
}

.slider2.round:before {
  border-radius: 50%;
}
.judgement-main-screen-subheader{color: var(--unnamed-color-4a4d4e);
  text-align: center;
  font: normal normal normal 24px/28px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
margin-left: -3rem}
.judges-redLine{
      text-align: center;
        margin-left: 40%;
        margin-bottom: 3rem;
        width: 191px;
        height: 3px;
        background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
        background: #E60000 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
}
.judges-header{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-5);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    /* margin-right: 7.5rem; */
    font: normal normal bold 50px/100px Vodafone Rg;
    letter-spacing: 5px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}