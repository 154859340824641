* {
  --color-grey1: #4a4d4e;
  --color-red1: #e60000;
  font-family: Vodafone Rg;
}
.login-pageContainer {
  background-color: rgba(0, 0, 0, 0.959);
  min-height: 100%;
  background-image: url("../../assets/numbersbg.png");
overflow-y: hidden;
 
}
.login-form-pageContainer {
  background: url('../../assets/mask.svg') no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0px;
  left: 0px;
  background-color: #860101;
}

.login-container {
  max-width: 37% !important;
  height: 70%;
  color: #4a4d4e;
  border: 3px solid rgba(255, 255, 255, 0.45);
  box-shadow: 0px 3px 6px #00000029;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: 4rem 2rem 3rem 2rem;
  padding: 2rem;
  padding-top: 1rem;
  text-align: center;
  justify-content: center;
}
.login-form-wrapper {
  justify-content: center;
}
.login-body-title {
  color: var(--text-primary);
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: .25rem;
  line-height: 2.5rem;
  opacity: 1;
  text-transform: uppercase;
  margin: 0px;
  padding-bottom: 1rem;
}

.login-form-body-subtitle {
  color: var(--color-grey1);
  text-align: center;
  font: normal normal normal 30px/30px Vodafone Rg;
  letter-spacing: 0px;
  opacity: 1;
}

.login-body-actionBtn1 {
  background: none;
  border: 0px;
  color: var(--text-primary);
  margin: 0px;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: .15px;
  border-radius: 8px;
  border: white 1px solid;
}

.login-body-actionBtn2 {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin-bottom: 1rem;
  width: 80%;
  height: 3rem;
  margin-top: 1rem;
  text-transform: uppercase;
}
.login-form-actionBtn {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: .15rem;
}

.login-form-container {
  width: 100%;
  height: auto;
  color: #4a4d4e;
  border: white 2px solid;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin: 4rem 2rem 3rem 2rem;
  padding: 2rem;
  text-align: center;
  justify-content: center;
}

.login-form-alert-customize {
  background: none !important;
  border: 0px !important;
  padding-left: 0px !important;
  color: var(--text-secondary) !important;
  padding: .375rem 0px 0px 0px !important;
}
.login-form-input {
  border-radius: 1rem !important;
  height: 2.5rem !important;
  border: 0.1rem solid #bebebe !important;
}

.login-form-input-text {
  color: var(--text-primary);
  text-align: left;
  letter-spacing: 0px;
  margin-top: 1rem !important;
}
.login-form-input-text > label {
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: .438rem;
}
.login-form-input-text input {
  border-radius: 1rem;
  height: 3rem !important;
}

.login-form-checkBox {
  margin-top: 1rem;
}
.login-form-checkBox-2 {
  text-align: left;
  color: #4a4d4e;
  opacity: 0.6;
  font-size: 0.9rem;
  margin-top: 1.2rem;
}
.login-form-checkBox-3 {
  text-align: left;
  color: #4a4d4e;
  opacity: 0.6;
  font-size: 0.9rem;
  margin-top: 2.5rem;
}

.login-forget-form-actionBtn {
  background-color: var(--color-red1);
  border: 1px solid var(--color-red1);
  border-radius: 1rem;
  color: white;
  margin-bottom: 1rem;
  width: 100%;
  height: 3rem;
}
.login-forget-form-actionBtn-email {
  background-color: var(--color-red1);
  border: 1px solid var(--color-red1);
  border-radius: 1rem;
  color: white;
  width: 100%;
  height: 3rem;
}

.login-change-account {
  color: red;
  text-align: right;
  font-size: 0.8rem;
  border: none;
  background-color: white;
}

.login-success-btn {
  background-color: var(--color-red1);
  border: 1px solid var(--color-red1);
  border-radius: 1rem;
  color: white;
  margin-bottom: 1rem;
  width: 100%;
  height: 3rem;
  margin-top: 2rem;
}

.entrance-Title {
  margin-top: 2rem;
}

.entrance-SubTitle {
  color: var(--unnamed-color-999999);
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}
.entrance-Btn {
  background-color: var(--color-red1);
  border: 1px solid var(--color-red1);
  border-radius: 1rem;
  color: white;
  margin-bottom: 1rem;
  width: 80%;
  height: 3rem;
  /* margin-top: 4rem; */
}
.forget-password-link {
  color: var(--gray-muted);
}


@media (max-width: 992px) {
  .login-container {
    max-width: 90% !important;
  }
    .login-form-actionBtn{
      font-size: 0.75rem;
    }
}
.success-message-note {
  margin: 2rem 0;
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  line-height: 1.875rem;
  color: white;
}
