@import '~bootstrap/dist/css/bootstrap.min.css';

.reg-step-container {
  padding-left: 2rem;
}

.nav-tabs.vertical {
  flex-direction: column;
  border: 1px solid #dee2e6;
}

.nav-pills .nav-link {
  border: 1px solid black;
  border-radius: 0px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.step-pills .step {
  display: flex;
  align-items: center;
  height: 7.6rem;
}

.step-pills .nav-link.active+.step-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-e60000);
  text-align: left;
  font: normal normal bold 1.25vw Vodafone Rg;
  letter-spacing: 2.4px;
  color: #E60000;
  text-transform: uppercase;
  opacity: 1;
}

.step-pills .nav-link.completed+.step-text {
  color: #e60000 !important;
 
  -webkit-margin-start: 1rem;
  margin-inline-start: 1rem;
}

.step-pills .nav-link+.step-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-ffffff);
  font: normal normal normal 1.25vw Vodafone Rg;
  text-align: left;
 
  letter-spacing: 2.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin-inline-start: 1rem;
}

.step-pills .nav-link {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 23px/27px var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-999999);
  text-align: right;
  font: normal normal normal 23px/27px Vodafone Rg;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.step-pills .nav-link {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 4rem 0rem;
  border-radius: 50%;
  border: 2px solid #b2b2b2 !important;
  color: #b2b2b2 !important;
  background-color: white !important;
  cursor: default;
}

.step-pills .nav-link.active {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 23px/27px var(--unnamed-font-family-vodafone-rg) !important;
  letter-spacing: var(--unnamed-character-spacing-0) !important;
  color: var(--unnamed-color-e60000) !important;
  text-align: right !important;
  font: normal normal normal 23px/27px Vodafone Rg !important;
  letter-spacing: 0px !important;
  color: #E60000 !important;
  opacity: 1;
  background-color: transparent !important;
  border: 1.5px solid var(--unnamed-color-e60000) !important;
  border: 2px solid #E60000 !important;
  opacity: 1;
}

.step-pills.flex-column .nav-link.completed {
  color: white !important;
  background-color: #e60000 !important;
  border-color: #e60000 !important;
}

.step-pills.flex-column .nav-link.completed:not(:last-child)::before {
  display: block;
  position: absolute;
  content: '';
  border: 1px solid #e60000;
  background-color: #e60000;
  top: 100%;
  height: 4.5rem;
  left: 50%;
  cursor: default;
}

.step-pills.flex-column .nav-link.active:not(:last-child)::before {
  display: block;
  position: absolute;
  content: '';
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
  top: 105%;
  height: 4.5rem;
  left: 50%;
  cursor: default;
}

.step-pills.flex-column .nav-link:not(:last-child)::before {
  display: block;
  position: absolute;
  content: '';
  border: 1px solid #b2b2b2;
  background-color: #b2b2b2;
  top: 105%;
  height: 4.5rem;
  left: 50%;
  cursor: default;
}

.step-pills.flex-column .nav-link.last.active::before {
  display: none;
}

.step-pills.flex-column .nav-link.last::before {
  display: none;
}

.back-btn {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-e60000);
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #E60000;
  text-transform: uppercase;
  opacity: 1;
  background-color: transparent;
  border: none !important;
}

.back-btn-container {
  display: flex;
  align-items: center;
}

.back-btn-container>img {
  width: 18px;
  height: 17px;
}

.next-btn {
  width: 183px;
  height: 48px;
  border: solid 1px white;
  border-radius: 10px;
  background-color: unset;
  color: white;
}

.next-btn-disabled {
  width: 183px;
  height: 48px;
  border: solid 1px white;
  border-radius: 10px;
  background-color: unset;
  color: white;
  cursor: not-allowed;
  font: normal normal normal 20px/23px Vodafone Rg;
}

.step-container-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 85%;
  position: absolute;
  bottom: 0;
}

.terms-text {
  color: #e60000;
  text-decoration: underline;
}

.reCaptcha-container {

  bottom: 2rem;
  right: 103px;
  z-index: 3;
}


@media (max-width: 868px) and (min-width: 767.98px) {
  .reCaptcha-container {

    right: 70px;
  }
    
}

@media (max-width: 767.98px) {
  .form-group {
    padding-right: 1rem !important;
  }

  .nav.step-pills.flex-column {
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-evenly;
    margin-inline-start: 3rem;
    border: none !important;
  }

  .reg-step-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 16px 16px 0px 0px;

    border: 0.5px solid var(--unnamed-color-ffffff);
    background: transparent linear-gradient(180deg, #4a4d4e91 0%, #0f0f10 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #ffffff;
    opacity: 1;
    height: 4rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
  }

  .back-btn-container {
    position: absolute;
    left: 6%;
    visibility: visible;
  }

  .step-id {
    display: none;
  }

  .step-pills {
    flex-direction: row !important;
    width: 25%;
    justify-content: space-evenly;
  }

  .step-container-btns {
    margin-bottom: unset !important;
    width: 27% !important;
  }

  .step-pills .step-text {
    display: none;
  }

  .step-pills.flex-column .nav-link:not(:last-child)::before {
    display: none !important;
  }

  .step-pills.flex-column .nav-link {
    width: 12px;
    height: 12px;
    background-color: aqua;
    padding: unset;
  }

  .reg-step-container .step {
    height: initial !important;
  }

  .reg-step-container .reduce-margin {
    margin-left: 4rem !important;
  }

  .reCaptcha-container {
    position: initial;
    margin-bottom: 0px;
  }
}

.stepper-container {
  padding: 1rem 2rem 1rem 1rem !important;
  ;
  border-radius: 10px;
  background-size: 100%;
  border: 3px #f8f9fa solid;


}