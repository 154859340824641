.summit-joinOuterContainer {
    display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        background-image: url("../../assets/numbersbg.png");
        background-color: rgba(0, 0, 0, 0.959);
        background-position: center;
        /* background-repeat: no-repeat; */
        background-size: contain;
        /* object-fit: cover; */
        min-height: 200vh;
        /* min-width: 99.5vw; */
        overflow: hidden;
        /* overflow-y: scroll; */
        /* position: fixed; */
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
   
   
}



.header {
    display: flex;
    justify-self: flex-start;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 18px/21px Vodafone Rg;
    letter-spacing: 0.09px;
    color: #FFFFFF;
    opacity: 1;
}
.summit-joinInput {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    width: 100%;
    height: 52px;
    margin-bottom: 2rem;
    font: normal normal normal 18px/21px Vodafone Rg;
    letter-spacing: 0.09px;
    color: #808080;
    opacity: 1;
}
.summit-heading {
margin: 1rem 0;;
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 40px/40px Vodafone Rg;
    letter-spacing: 4px;
    line-height: 3rem;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    display: flex;
    font-weight: 400;

}
.title-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.summit-heading-bold {

    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 43px/43px Vodafone Rg;
    letter-spacing: 4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    display: flex;
    font-weight: 900;
    margin-right: 1rem;
}
.login-container {
    min-width: 75%!important;
   
    color: #4a4d4e;
    border: 3px solid hsla(0,0%,100%,.45);
    box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
    align-items: center;
    border-radius: 16px;
    margin: 4rem 2rem 3rem;
    padding: 1rem 2rem 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
   
}
.label-format {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    display: flex;
    /*  */
    align-items: center;
}

@media (max-width: 992px) {
    .login-container {
        max-width: 90% !important;
    }
 .label-format {
    
     font: normal normal normal 18px/16px Vodafone Rg;
    justify-content: space-between;
 }
 .radio-text {
     max-width: 50% ;
 }
}
.summit-btn:disabled {
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 28px/32px Vodafone Rg;
    letter-spacing: 0.14px;
    color: #999999;
    text-transform: capitalize;
    opacity: 1;
    background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
    background: #CCCCCC;
    border-radius: 8px;
    opacity: 1;
    border: none;
}

.summit-btn {
    font: normal normal bold 28px/28px Vodafone Rg;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: transparent;
    opacity: 1;
    display: flex;
    font-weight: 400;
    border: 2px white solid;
    border-radius: 8px;
    padding: 0.75rem 2.2rem;
    min-width: 14rem;
    margin: 0.5rem;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
}

.summit-btn:hover:enabled {
    background-color: #565555;
    text-shadow: none;
    color: white;
}

.mt-20 {
    margin-top: 20px;
}

.btns-container {
    display: flex;
    justify-self: center;
    margin-left: 1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
        height: 100%;
    }

    .joinInnerContainer {
        width: 50%;
    }

    .heading {
        /* width: 23%; */
    }

    .btn-width {
        /* width: 23%; */
    }

    .join-btns-container {
        /* width: 23%; */
    }

    .back-btns-container {
        /* width: 23%; */
        display: flex;
        flex-direction: column;
    }

    .back-btns-container>a {
        margin-bottom: 1rem;
    }
}

button:focus {
    outline: 0;
}

@media (min-width: 764px) and (max-width: 770px) {
    .joinOuterContainer {
        /* display: flex; */
    }

    .joinInnerContainer {
        width: 37%;
        display: flex;
        flex-direction: column;
        /* margin-right: 23%; */
    }
}

@media (min-width: 538px) and (max-width: 542px) {
    .joinInnerContainer {
        width: 50%;
    }
}

@media (min-width: 278px) and (max-width: 282px) {
    .joinInnerContainer {
        width: 75%;
    }

    .back-btns-container>a {
        margin-right: 0.5rem;
    }
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer{
    display: flex;
   /* align-items: center;
   justify-content: center; */
   color: white;
   
    
}
.hyperLinks{
    color: white !important;
    cursor: pointer;
    text-decoration: underline;
    }
.hyperLinks:hover{
color: white !important;
}