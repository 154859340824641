.success-submission-container {
  background-color: var(--background);
  background-image: url('../../../assets/Pattern.svg');
  background-size: cover;
  overflow: auto;
  height: 100vh;
}

.success-submission-container .success-main {
  height: calc(100vh - 6.25rem);
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding-bottom: 5rem;
}

.success-submission-container .success-main-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: none;
  border: none;
}

.success-submission-container .success-message {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  color: var(--text-primary);
}

.success-submission-container .success-message>* {
  width: 100%;
}

.success-submission-container .success-message .success-message-header {
  font-size: 3.125rem;
  letter-spacing: 0.313rem;
  line-height: 6.25rem;
  font-weight: bold;
}

.success-submission-container .success-message .success-message-note {
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  line-height: 1.875rem;
}

.success-submission-container .login-btn {
  border: 2px solid var(--text-primary);
  background: none;
  border-radius: 0.625rem;
  width: 25rem;
  height: 3rem;
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

@media (max-width: 991.98px) {
  .success-submission-container .success-message .success-message-header {
    font-size: 1.75rem;
    line-height: 2.375rem;
    letter-spacing: 0.175rem;
  }

  .success-submission-container .success-message .success-message-note {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0rem;
    margin-top: 1.5rem;
  }

  .success-submission-container .login-btn {
    font-size: 1.125rem;
    letter-spacing: 0.1125rem;
    line-height: 1.875rem;
    text-transform: uppercase;
    margin-top: 1.5rem;
    height: 2.5rem;
    width: 10.25rem;
  }
}