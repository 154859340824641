.not-found-container {
  text-align: center;
  padding: 50px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.not-found-container h1 {
  font-size: 120px;
  margin: 0;
  color: #e60000;
}

.not-found-container h2 {
  font-size: 30px;
  margin: 20px 0;
  color: #333;
}

.not-found-container p {
  color: #666;
  margin-bottom: 30px;
}

.home-link {
  display: inline-block;
  padding: 10px 30px;
  background-color: #e60000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.home-link:hover {
  background-color: #cc0000;
  text-decoration: none;
  color: white;
}
