.faq-page-wrapper{
    background-color: rgba(0, 0, 0, 0.959);
    min-height: 100%;
background-image: url("../../assets/numbersbg.png");
  overflow-y: hidden;
}
.faq-page-title{
    text-align: center;
    font: normal normal bold 50px/100px Vodafone Rg;
    letter-spacing: 5px;
    color: #FFFFFF;
    
}
.underline:after {
	content: '';
	display: block;
	background-color: #E60000;
  margin-left:auto;
  margin-right: auto;
  width: 15%;
  height: 4px;

}

.faq-page-subtitle{
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

/* .faq-accodrion-container{
  height: 100%;
} */

@media only screen and (max-width: 280px) {
    .faq-page-title{
      font-size: 2.125rem;
    }
    .faq-accodrion-container{
      margin: 1.5rem;
      font-size: 1.3rem;
    }
  }