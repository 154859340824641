.ddl-btn,
.ddl-btn:hover {
    background-color: #e60000;
    width: 100%;
    height: 30px;
    flex-shrink: 0;
    color: #FFF;

    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.255px;
    border: none;
    border-radius: 8px;
}