.submission-loader-back {
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
}
.submission-loader-back2 {
    align-items: center;
    justify-content: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
}
.submissionLoaderTextHeader{
    font-size:3rem;
        letter-spacing: 0.15rem;
        line-height: 3.5rem;
        margin: 5rem;
        color: white;
}
.submissionLoaderTextnote {
    font-size: 1.5rem;
    letter-spacing: 0.15rem;
    line-height: 3.5rem;
    color: white;
}