.wiv-joinOuterContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-image: url("../../assets/loadingbg.png");

    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
width: 100%;
height: 100vh;
    position: absolute;
    z-index: 1000000;

    top: 0;
    left: 0;
}
.welcome-text2 {
    margin-top: 1rem;
    width: 15rem;}
.welcome-text {
    margin-top: 1rem;
    width: 7.5rem;
    /* 
    color: #000;

    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; */
}

.Loading-title {
    color: #FFFFFF;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.first-section {

    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px solid #FCFCFC;
    box-shadow: 0px 4px 20px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10px;

    padding: 3rem 1rem;
    margin: 2rem 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 28rem
}

.img {
    z-index: 9999;
    width: 15rem;
    height: 15rem;

}

.wiv-tip-text-bolder {
    color: #E60000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wiv-tip-text {
    padding: 1rem 0;
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 75%;
    padding-left: 1rem;
}

.footer {
    position: "absolute";
    bottom: "7.5rem";
    display: "flex";
    flex-direction: "column";
    align-items: "center";
    justify-content: "center"
}

.for-better {
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    display: block;
    color: #000;
}

.brought-to {
    font-family: 'Vodafone Rg';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    /* identical to box height */

    display: block;
    color: #000;
}

.header-div {
    position: absolute;
    z-index: 10000;
    right: 3%;
    top: 5%;
    cursor: pointer;
}
.moving-logo {
    width: 100%;
    padding: 2rem 0 0 0;
    height: 61vh;

}
.slick-ss {
    overflow-x: hidden;
    width: 99vw !important;
}
.instructionslick-ss{
overflow-x: hidden;
    width: 99vw !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;
        background-image: url("../../assets/instrupopup.svg");
        height: inherit;
            background-repeat: no-repeat;
            background-position-x: center;
            background-size: 96%;
}
.instructionCard{
  
    
    
            background-position: center;
    
          
            height: 100%;
            max-width: 20rem !important;
    
            background-size: 100% 100%;
    
    
      
}
.single-div {
    background-size: 100%;
    background-repeat: no-repeat;


    /* margin-bottom: 2rem !important; */

    background-image: url("../../assets/section-frame.svg");
    /* height: auto;
     */
    width: 100%;
    height: 62.5vh;
    /* margin-right: 1rem; */

}
.title {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 128px;
    height: 39px;
    flex-shrink: 0;
    background: #E60000;
    ;
    display: flex;
    align-items: center;
    justify-content: center;

}
.imges-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1.5vw;
    align-items: center;
}
.card-content{
    padding: 2rem;
}
.card-content-body{
    color: #FFF;
        text-align: center;
        font-size: 1.5vw;
       
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
}
.instruction-img{
    width: 7.5vw;
    margin: 0 1rem;
}
.instruction-img2 {
    width: 5vw;
}
.header-div {
    position: absolute;
    z-index: 10000;
    right: 3%;
    top: 5%;
    cursor: pointer;
    color: white;
}
.first-section {

  display: flex;
  justify-content: center;
  align-items: center;
    display: flex;
    height: 50vh;
        width: 50vw;
        border: none;
}
.other-section {
    
    display: none;
    border: none;
height: 50vh;
    width: 50vw;
}
.img {
    z-index: 9999;
    
    height: inherit;
    width: inherit;

}