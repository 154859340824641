.cinema-asc-container {
  height: 100vh;
  width: 100%;
  justify-content: center;
  background: url("../../../assets/mask.svg") no-repeat center center fixed;
  background-size: cover;
  background-color: #860101;
  display: flex;
}
.session-body {
  width: 100vw;
  height: 100vh;
  background-color: black;
  overflow: hidden;
}
.asc-countdown {
  /* background-image: url("../assets/waiting.png"); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}



.live-video {
  width: 100%;
  height: 100%;
  margin-top: 3rem;
  width: 64%;
  height: 64%;
}

.cinema-container {
  /* background-image: url("../../assets/cinema.png"); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.cinema-container {
  /* background-image: url("../../assets/cinema.png"); */
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.row-container {
  height: 100%;
  padding-top: 1%;
}

.QA-container {
  background-color: transparent;
  border: none;
}

.video-container {
  margin-left: 0rem;
  height: 76vh;
}

.slido {
 
}
.slido-container {
  position: relative;
  top: -87.8%;
  left: 1.5%;
  width: 24%;
  z-index: 100;
  border-radius: 0px 10px 10px 0px;
}

.QA-button {
    background-color:transparent;
    color: white;
      /* -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); */
      border-radius: 0px 10px 0 0;
   border: none;
   position: relative;
   height: 2rem;
   
}
/* .QA-button:hover {
  background-color: #860101;
  color: white;
  text-decoration: none;
  cursor: pointer;
} */

.btn-back-container {
  width: 95%;
  
}

.btn-start-finish {
border: 3px solid var(--unnamed-color-ffffff);
background: #000000 0% 0% no-repeat padding-box !important;
border: 3px solid #FFFFFF;
border-radius: 10px;
opacity: 1;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
letter-spacing: var(--unnamed-character-spacing-2-4);
color: var(--unnamed-color-ffffff);
text-align: center;
font: normal normal normal 24px/30px Vodafone Rg;
letter-spacing: 2.4px;
color: #FFFFFF !important;
text-transform: uppercase;
opacity: 1;
cursor: pointer;
  /* text-decoration: none; */
}

.btn-session {
  position: fixed;
  top: 5%;
  right: 6.5rem;
  z-index: 3;
  background-color: #e60000;
  border-radius: 0.5rem;
  border: 1px solid #860101;
  color: white;
  width: 6rem;
  text-align: center;
}
.slido {
 
}

.slido-container {
  position: relative;
  bottom: 2%;
  left: 3%;
  width: 24%;
  max-height: 40vh;
  z-index: 100;
}


.btn-back-container {
  width: 95%;
}







@media screen and (max-width: 578px) {
  .video-container {
    margin: 0% 19% 38% 1.5%;
  }
}

@media screen and (max-width: 578px) {
  .live-video {
    margin: 0% 19% 38% 2%;
    width: 100%;
  }
}

@media screen and (max-width: 578px) {
  .session-back-btn {
    position: absolute;
    right: 1%;
  }

  .date {
    color: white;
    margin-bottom: 2rem;
    font-size: 2rem;
    display: flex;
  }
  .date .unit {
    width: 3rem;
    height: 3rem;
    background-color: white;
    color: #e60000;
    border-radius: 50%;
    text-align: center;
  }
}
