.photobooth-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-3-2);
    color: var(--unnamed-color-ffffff);
    text-align: right;
    font: normal normal bold 32px/30px Vodafone Rg;
    letter-spacing: 3.2px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 1rem;
}
.photobooth-desc{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.mosaicwall-btn{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
        letter-spacing: var(--unnamed-character-spacing-2-4);
        color: var(--unnamed-color-ffffff);
        text-align: center;
        font: normal normal normal 24px/30px Vodafone Rg;
        letter-spacing: 2.4px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        background-color: transparent !important;
        padding-top: 1rem;
        border: none;
}