.requiredColor {
  color: #e60000;
}
.form-control {
  height: 100% !important;
}

.borderBottom {
  border-bottom: 0.1rem solid #cccccc !important;
}

.white {
  color: #ffffff !important;
}
.white-1 {
  color: #cccccc !important;
}
.blue {
  color: #007c92 !important;
}
.red-1 {
  color: #e60000 !important;
}
.grey-1 {
  color: #999999 !important;
}
.grey-2 {
  color: #707070 !important;
}
.grey-3 {
  color: #4a4d4e !important;
}

.card {
  border: none !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.card-body1 {
  height: auto;
  padding: 1rem;
  margin-left: 1rem;
  margin-bottom: 2rem;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-cccccc);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 16px;
  opacity: 1;
}

.card-title1 {
  text-align: left;
  color: #4a4d4e;
  font: normal normal normal 20px/20px Vodafone Rg;
  letter-spacing: 0px;
  opacity: 1;
}
.card-text1 {
  text-align: start;
  color: #4a4d4e;
  font: normal normal normal 16px/18px Vodafone Rg;
  letter-spacing: 0px;
  opacity: 1;
}

.form-check-label {
  text-align: left;
  font: normal normal normal 16px/18px Vodafone Lt;
  letter-spacing: 0px;
  color: #4a4d4e;
}

.projectDetails-data {
font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.15rem;
  color: white;
  margin-bottom: 1.5rem;
  text-decoration: underline;
}

.ProjectDetails-teamMembers {
  list-style-type: none;
  padding: 0px;
  color: var(--gray);
}
.ProjectDetails-teamMembers .projectDetails-data {
  display: block;
  color: var(--dark-gray);
  margin-bottom: 0rem;
}
