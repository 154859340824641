.required {
  color: white;
}

.reg-alert {
  background-color: unset !important;
  color: #B3261E !important;
  border: unset !important;
  padding: unset !important;
      max-height: 3rem;
}

.field-icon {
  /* float: right; */
  margin-left: -25px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.btn-verify-send-verefied {
  border: 1px solid #007c92;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: white;
  background-color: #007c92;
  width: 79px;
  height: 40px;
  cursor: pointer;
  margin-top: 2.5rem;
}

.btn-verify {
  border: 1px solid #007c92;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  margin: 2.3rem auto;
}
.verify-form {
  margin-left: 0.1rem !important;
}
.btn-position {
  position: relative;
  z-index: 1;
}
.verify {
  width: 100%;
}
.email-row {
  height: 6rem;
}
.phone-row {
  height: 6rem;
  margin-top: 4.5rem;
}
.btn-verify-send {
  border: 1px solid #007c92;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  cursor: pointer;
  margin-top: 2.5rem;
}

.btn-phone-position {
  position: relative;
  z-index: 1;
}
.btn-phone-verify {
  border: 1px solid #007c92;
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #007c92;
  width: 79px;
  height: 40px;
  margin-top: 0.1rem;
}
.phone-verify {
  position: relative;
  bottom: 3.5rem;
}
.image-res-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: unset;
}
.file-res-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.file-res-input-container-text {
  color: #e60000;
  -webkit-margin-start: 1rem;
          margin-inline-start: 1rem;
}
.file-name {
  color: #4a4d4e;
}
.change-file-btn-text {
  color: #e60000;
  text-decoration: underline;
}
.form-check1-input {
  width: 15px;
  height: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccd;
  border-radius: 6px;
  opacity: 1;
}
.form-check1-label {
  text-align: left;
  font: normal normal normal 18px/18px Vodafone Lt;
  letter-spacing: 0px;
  color: #4a4d4e;
  position: absolute;
}

.registration-stepper-container {
  margin-left: 2rem;
}
/* .main-user-form-body-container {
  padding: 1rem;
} */
.input-width-80 {
  width: 80%;
}
