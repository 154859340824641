/* backdrop */
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  opacity: 0.9;
  z-index: 1000;
}
.backdrop {
  width: 94%;
  height: 100%;
  background: #222222;
  opacity: 0.9;
  z-index: 1000;
  border-radius: 50px;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}
#loader-wrapper .loader-section .section-text {
  color: white;
  opacity: 1;
}

div.clickEffect {
  position: fixed;
  box-sizing: border-box;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
  animation: clickEffect 0.4s ease-out;
  z-index: 99999;
}
@keyframes clickEffect {
  0% {
     opacity: 1;
    width: 0.5em;
    height: 0.5em;
    margin: -0.25em;
    border-width: 0.5em;
  }
  100% {
    opacity: 0.2;
    width: 15em;
    height: 15em;
    margin: -7.5em;
    border-width: 0.03em;
  }
}
.frontImage {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 10px;
}
.menu{
  display: flex;
    flex-direction: row;
    z-index: 100;
    position: absolute;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    
    bottom: 1rem;
}
.dropdown-hall-menu{
  width: 100%;
background: #FFFFFF72 0% 0% no-repeat padding-box !important;
  border-radius: 10px;

}
.dropdown-hall-menu>a{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-lt);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 18px/30px Vodafone Lt;
    letter-spacing: 1.8px;
    color: #000000 !important;
    opacity: 1;
    border-bottom: 1px black solid;
}
.dropdown-hall{
  background-color: transparent !important;
  border: none;
}
.dropup .dropdown-toggle::after{
  display: none !important;
}
.modal-content-info{
  
  background: rgba(0, 0, 0, 0.66) 0% 0% no-repeat padding-box !important;
    /* border: 1px solid #FFFFFF !important; */
    border-radius: 10px !important;
    width: 100vw !important;
    left: 0 !important;
  top: 3rem !important;
}
.modal-info-body{
  /* margin: 0 !important;
  width: 100vw !important; */
  justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modal-info-body>.logo{
  width: 389px;
    height: 34px;
}
.modal-info-body>.modal-info-header>.info-header{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-3-2);
    color: var(--unnamed-color-ffffff);
    text-align: right;
    font: normal normal bold 32px/30px Vodafone Rg;
    letter-spacing: 3.2px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    
}
.round-div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  flex-direction: column;
}
.round-div>.round-img{
  width: 75px;
    height: 79px;

}
.round-div>.round-text {
  margin-top: 1rem;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    

}
.instructions-div{
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.instructions-div>.round-div>.round-text{
  width: 65%;
}
.modal-info-body>.modal-info-header>.close-btn{
color: #FFF;
background-color: transparent;
border: none;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  float: right;
      margin: 0;
      position: absolute;
        right: 2%;
}
.modal-info-body>.modal-info-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  justify-content: center;
    width: 100%;
}

.halliframe{
  width: 100%;
    height: 100vh;
    right: 0;
    padding: 0;
    margin: 0;
    overflow: hidden !important;
}
.mobile-bg{
  
  background-color: #000000;
  color: #FFFFFF;
  height: 100vh;

}
.mobile-bg-div{
  z-index: 1;
  display: flex;
    flex-direction: column;
    align-items: center;
      padding: 2rem 2rem 0 2rem;
          position: relative;
}
.hack{
  max-width: 100%;
  margin: 2rem 0;
}
.mobile-bg-text{
  color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.2px;
}
.img-disclaimer{
position: absolute;
  width: 100%;
  height: 58vh;
  bottom: 0;
}

.popover__content {
  opacity: 0;
    visibility: hidden;
    display: none;
    position: absolute;
    right: 3%;
    bottom: -5rem;
    transform: translate(0, 10px);
    background-color: transparent;
   
    width: auto;
    
}



 .showclass{
  
z-index: 10;
  opacity: 1;
  display: block;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  height: 100%;
    bottom: -2rem;
    right: 5rem;
}

.popover__message {
 position: relative;
  bottom: 4rem;
  color: var(--Body-Text-Base-Color, #68717A);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  width: 17rem;
  margin: 0 1.5rem;
}