
.terms-page-container{
    background-color: rgba(0, 0, 0, 0.959);
    background-image: url("../../assets/numbersbg.png");
    opacity: 1;
     top: 0px;
    left: 0px;
}
 .terms-page-title{
    text-align: center;
    font: normal normal bold 50px/100px Vodafone Rg;
    letter-spacing: 5px;
    color: #FFFFFF;
    font-size: 3.125rem;
    
}

.terms-card-container {
    text-align: left;
    height: 100%;
    border: white 2px solid;
    border-radius: 2rem;
    padding: 2rem;
   
    border: 3px solid var(--unnamed-color-ffffff);
      box-shadow: 0px 3px 6px #00000029;
      border: 3px solid #FFFFFF;
      border-radius: 16px;
      opacity: 1;
  }
  .terms-card-container>ul>li{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-ffffff);
      text-align: left;
      font: normal normal normal 24px/32px Vodafone Lt;
      letter-spacing: 0px;
      color: #FFFFFF;
  }
  .underline:after {
    content: '';
    display: block;
    margin-left:auto;
    margin-right: auto;
    background: #E60000 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

}



  @media (min-width: 360px) {
    .terms-card-container{
      margin: 1.5rem;
    }

  }
  @media only screen and (max-width: 280px) {
    .terms-page-title{
      font-size: 2.125rem;
    }
    .terms-card-container{
      margin: 1rem;
      font-size: 1.2rem;
      padding: unset;
    }
  }