.main-agenda-bg{
    background: url("../../../assets/agenda-bg.png");
    background-repeat: no-repeat;background-size: cover;
    background-color: black;
      width: 100vw;
      height: 100vh;
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
}
.videocreenheader {
  display: flex;
  flex-direction: row;

width: 1186px;
}
.logos-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.vois-logo {
  margin: 1rem 0 0 0;
  width: 83.824px;
  height: 20.902px;
}

.hack {
  width: 193px;
  height: 25px;
  margin: 1rem 0 1rem 0;
}
.footer-main-div {
      display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        /* border: #FFF 1px solid; */
        align-items: center;
        flex-direction: column;
        /* height: 5rem; */
        z-index: 100000000;
        bottom: 2vh;

}

.footer-content {
    display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 11%;
      padding: 0px 0.5rem;
      margin-top: 1rem;
      width: 1206px;
}

.left-section {
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.left-section-content {
  color: #FFF;

  font-size: 1vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.6px;
  margin-right: 1.5vw;
}

.right-section-content {
  width: 23.998px;
  height: 24px;
  margin-left: 16px;
  cursor: pointer;
}

.line {
  padding-top: 3rem;
  cursor: pointer;
}

.line-img {
  display: none;
}

.back-txt{
  margin-left: 0.5rem;
  color: #FFF;
  
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.1px;
}
.back-div{
 display: flex;
 align-items: center;
 cursor: pointer;
}
.dates-list{
  border-radius: 6px;
    border: 1px solid #E60000;
    float: left;
      min-height: 280px;
      list-style-type: none;
   background-color: rgba(0, 0, 0, 0.43);
      padding: 0;
}
.dates{
  margin-top: 2rem;
  width: 35%;
  max-height: 65vh;
  overflow-y: auto;
}
.date-item.selected{
  border-radius: 6px 6px 0px 0px;
    background: #D60101;
    height: 94px;
    display: flex;
    flex-direction: column;
    padding: 0 0.75rem;
    justify-content: space-between;
}.date-item {border-radius: 6px 6px 0px 0px;
  background: transparent;
  height: 94px;
padding: 0 0.75rem;
justify-content: space-between;display: flex;
  flex-direction: column;}
  .day{
    color: #FFF;
    
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 1rem;
  }
  .weekday{
color: #FFF;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  
  }.weekdate {color: #FFF;
  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
  }
    .agenda{
      margin-top: 2rem;
        border: 1px solid #E60000;
        width: 75vw;
        border-radius: 6px;
        height: fit-content;
    }
    .main-div-container{
      display: flex;
      flex-direction: row;
    width: 1186px;
    }
    .agenda-list{
          float: left;
         height: 100%;
          list-style-type: none;
        width: 100%;
      padding: 0;
        margin: 0;
        max-height: 65vh;
          overflow: auto;
    }
        .agenda-item:nth-child(even) {
          border-radius: 5px 5px 0px 0px;
            background: #000;
          height: 77px;
            display: flex;
            flex-direction: row;
            width: 100%;align-items: center;
        }
                .agenda-item:nth-child(odd) {
                  border-radius: 5px 5px 0px 0px;
                  background: #1A1919;
        
                  display: flex;
                  flex-direction: row;
                  height: 77px;
                  width: 100%;
                  align-items: center;
                }
                .time{
                  padding: 1rem;
                  width: 5rem;
                  color: #FFF;
                  
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    border-right: 1px white solid;
                }
                .speaker-div{
                  display: flex;
                  flex-direction: column;
                  padding-left: 1rem;
                }
                .speaker{
                  color: #FFF;
                  
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
                .title{
                  color: #FFF;
                  
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                                                            .coaches-box {
                                                              border-radius: 6px;
                                                              border: 1px solid #E60000;
                                                              float: left;
                                                            
                                                              list-style-type: none;
                                                              background-color: rgba(0, 0, 0, 0.43);
                                                              padding: 0;
                                                              display: flex;
                                                              flex-direction: column;
                                                              justify-content: space-between;
                                                              align-items: center;
                                                              padding: 1rem 0.5rem;
                                                                

                                                            }
                                                            .coaching-sessions-title{
                                                              color: #FFF;
                                                              font-size: 24px;
                                                              font-style: normal;
                                                              font-weight: 400;
                                                              line-height: normal;
                                                              text-transform: uppercase;
                                                              padding-bottom: 0.5rem;
                                                            }
                                                                                                                        .coaching-sessions-dates {
                                                                                                                          color: #FFF;
                                                                                                                          font-size: 32px;
                                                                                                                          font-style: normal;
                                                                                                                          font-weight: 500;
                                                                                                                          line-height: normal;
                                                                                                                          text-transform: capitalize;
                                                                                                                          padding-bottom: 0.5rem;
                                                                                                                        }
                                                                                                                                                                                                                                                .coaching-sessions-btn {
                                                                                                                                                                                                                                                  color: #E60000;
                                                                                                                                                                                                                                                  font-size: 22px;
                                                                                                                                                                                                                                                  font-style: normal;
                                                                                                                                                                                                                                                  font-weight: 400;
                                                                                                                                                                                                                                                  line-height: normal;
                                                                                                                                                                                                                                                  text-transform: capitalize;
                                                                                                                                                                                                                                                  cursor: pointer;
                                                                                                                                                                                                                                                }