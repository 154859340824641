
.video-container {
    height: 100vh;
    width: 100%;
    position: relative;
    background-image: url('../../assets/hack-banner3.png');
    background-repeat: no-repeat;
    background-size: cover;
background-position: center;
}

.video-container video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}
.first-section-div{
    width: 100%;
    padding: 2rem 2rem 0 2rem;
    justify-content: center;
    display: flex;
    position: absolute;
        z-index: 1;
        flex-direction: column;
        top: 2rem;
        align-items: center;
        justify-content: space-between;
        height: 95vh;
}
.slogan-div{
   
    width: 35%;
    background-position: center;
    
    /* background-image: url("../../assets/_VOIS_Hackathon_logo.svg"); */
    /* height: 35vh; */
    background-size: 100% 100%;
    margin-bottom: 10rem;
  
}
.first-section-div-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.countdown-number-sub-small{
  

    color: #FFF;
    
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: capitalize;
}
.sectionDeadlineDateBox-small{
    
    
        background-image:url('../../assets/counterbg.svg');
    
    
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #FFF;
     
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        border-radius: 4px;
    text-align: center;
        padding: 1.25rem 1.25rem;
        margin: 0.25rem 0.5rem;
        width: 5.625rem;
        justify-content: center;
            align-items: center;
            display: flex;
}
.register-big-btn{
    width: 200px;
        height: 56px;
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1.6px;
        text-transform: uppercase;
    color: #FFF;
    
        
        line-height: normal;
      
        background: #E60000;
            border: none;
            margin: 1rem 0;
            cursor: pointer;
            border-radius: 6px;
}
.fullcounter-div{
    margin-bottom: 16px;
}
.eachcounter-div{
    margin-right: 16px;
}
.starts-in{
    color: #FFF;
    
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border-radius: 4px;
        text-align: center;
        padding: 1.25rem 1.25rem;
        margin: 0.25rem 0.5rem;
       
        justify-content: center;
        align-items: center;
        display: flex;
}
@media only screen and (max-width: 767.98px) {
    .eachcounter-div {
            margin-right: 0;
        }
    .slogan-div {width: 75%;}
        .countdown-number-sub-small {font-size: 20px;}
        .first-section-div {top:25%}
.video-container {
    
    background-image: url('../../assets/hack-bannermobile2.png');
    background-size: 100% 100%;
        background-position-y: center;
}
.first-section-div {
    height: 84vh;
    padding: 0;
        margin: 0;
        width: 100%;
        top:0
}
.sectionDeadlineDateBox-small {
   
    width: 67.304px;
    height: 67.304px;
    font-size: 16px;
        line-height: normal;
}
.countdown-number-sub-small {color: #FFF;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.8px;
}
.register-big-btn {

    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1.2px;
    text-transform: uppercase;}

}