.scrollIt{
  display: block;
  overflow-x: auto;
}
/* scrollar styling */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F1F1F1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C1C1C1; 
}

.table-fixed tbody {
  /* height: 300px; */
  max-height: 18.75rem;
  /* overflow-y: auto; */
  width: 100%;
  overflow: auto;
}

.table-fixed thead,
/* .table-fixed tbody, */
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.project-table .table-head-row {
  display: flex;
}
.project-table .table-head-row .table-head-row-cell {
  border: 1.4px solid #191818;
  display: flex;
  justify-content: center;
  color: #4A4D4E;
  font-size: 1.125rem;
  background-color: white;
  text-transform: uppercase;
      word-break: break-word;
}

.project-table .table-head-row .table-head-row-cell:first-child {
  border-radius: 10px 0px 0px 0px;
}

.project-table .table-head-row .table-head-row-cell:last-child {
  border-radius: 0px 10px 0px 0px;
}

.project-table .project-name {
  color: white;
}
.project-table .project-detail{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/20px var(--unnamed-font-family-vodafone-rg);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 18px/20px Vodafone Rg;
    letter-spacing: 1.8px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.project-table .table-body-row {
  display: flex;
}
/* .project-table .table-body-row:last-child {
  border-bottom: 1px solid white;
} */
.project-table .table-body-row .table-body-row-cell {
  border: 1.4px solid #191818;
  background-color: white;
  /* color: black; */
}

.project-table .table-body-row .table-body-row-cell:first-child {
  border-radius: 0px 0px 0px 10px;
}
.project-table .table-body-row .table-body-row-cell:last-child {
  border-radius: 0px 0px 10px 0px;
}

/* .project-table {
  max-height: 75vh;
} */
.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
  float: left;
  position: relative;
}

.table-fixed thead > tr > th::after {
  content: "";
  clear: both;
  display: block;
}

.project-table .project-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.project-table .project-container .criteria-weight {
  font-size: 2rem;
  color: #4A4D4E;
  margin-inline-start:0.5rem;
}

.project-table .project-name {
  color: white;
  /* font-size: 20px; */
  /* font-size: 1.25rem; */
  font-size: 2rem;
  margin-bottom: 1rem;
  position: sticky;
  left: 0;
}
.project-table .project-icon {
  color: white;
  /* font-size: 20px; */
  /* font-size: 1.25rem; */
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: sticky;
  left: 0;
}
.rail-select {
  position: relative;
  width: 55%;
  border-color: #4A4D4E;
}
.rail-select select {
  border-color: #4A4D4E !important;
  border-radius: 10px !important;
  font-size: 1.5rem;
  color: #4A4D4E;
  height: 2.625rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.rail-select select:focus {
  font-size: 1.5rem;
  outline: none;
  box-shadow: none;
  color: #4A4D4E;
}
.rail-select .select-side {
  pointer-events: none;
  width: 2.5rem;
  position: absolute;
  top: 5px;
  background-color: unset;
  height: 100%;
  left: 50%;
  border-radius: 16px;
}
.rail-select .select-side:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
}
.rail-select .select-side i {
  left: 30%;
  top: 0.75rem;
}
.rail-select .select-side i.blue {
  color: #4e7af0;
}
/* .table-fixed thead tr {
  color: white;
} */
/* .table-body-row-cell {
  border-right: 2px solid white !important;
  border-left: 2px solid white !important;
} */
