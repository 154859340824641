.failSubmission-container{
  background-color: rgba(0, 0, 0, 0.959);
  opacity: 1;
  top: 0px;
  left: 0px;
  min-height: 100vh;
}
.failsubmission-main{
 height: calc(100vh - 200px);
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
 align-items: center; 
}
.failsubmission-page-title{
  text-align: center;
  font: normal normal bold 50px/100px Vodafone Rg;
  letter-spacing: 5px;
  color: #FFFFFF;
  font-size: 3.125rem;
}

.loginBtn, .project-submission-errors-container .error {
  font-size: 1.5rem;
  line-height: 2rem;
}
.loginBtn {
  background: none;
  border: 2px solid var(--gray);
  border-radius: 1rem;
  width: 300px;
  height: 48px;
  
  margin-bottom: 1rem;
  text-transform: capitalize;

  color: var(--text-primary);
  
}
.project-submission-errors-container{
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.project-submission-errors-container .errors-list{
  width: 100%;
  text-align: center;
  list-style-type: none;
  padding: 0px;
}
.project-submission-errors-container .error{
  color: var(--text-secondary);
}

/* 
.maindiv {
    padding: 0 1.5rem;
    overflow: hidden;
    background-image: url("../../../assets/mask.svg");
    background-color: #860101;
    margin: 0 !important ;
    width: 100%;
  }
  .subParentDiv {
    margin-top: 10%;
    color: #4a4d4e;
    border: white 2px solid;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    margin-bottom: 5rem;
    height: 90%;
  }
  .parent {
    margin: 2rem;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 80%;
    color: #4a4d4e;
  }


.failSubmission-img-size
{
  width: 30%;
  padding-bottom: 2rem;
} */
