.step-container {
  display: flex;
  justify-content: center;
  margin: 0px;
  width: 100%;
}
.nav-pills {
  width: 714.5px;
}

.circle {
  width: 7.1rem;
  height:7.1rem;
  
  /* UI Properties */

  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #000000 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF;
  opacity: 1;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: NaNpx;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 80px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-40) / var(--unnamed-line-spacing-40)
    var(--unnamed-font-family-vodafone-rg);

  font: normal normal bold 25px/25px Vodafone Rg;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.circle-final {
  height: 7.1rem;
  width: 7.1rem;
  /* UI Properties */

  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #3875A6 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  opacity: 1;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: NaNpx;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 80px;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);

  font: normal normal bold 25px/25px Vodafone Rg;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.day{
display: flex;
  justify-content: center;
  
}
.circle .circle-text > * {
  width: 100%;
  margin-bottom: 0.25rem !important;
}
.circle-text > :first-child {
  font-weight: bold;
  line-height: 1rem;
  font-size: 2rem;
}
.circle-text > :last-child {
  font-weight: initial;
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  line-height: 1rem;
}

.line {
  height: 7rem;
    border: 4px solid var(--unnamed-color-ffffff);

    opacity: 1;
    background-color: white;
    width: 3px;
}
.step-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 40px/40px Vodafone Rg;
    letter-spacing: 2px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.step-desc {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;

    opacity: 1;
}

.step-desc-container {
  height: 7.1rem;
  justify-content: center;
}
.section5-redline {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 11.938rem;
  height: 3px;
}
.entry-header {
  margin-bottom: 1.1rem;
}

@media only screen and (max-width: 991.98px) {
  .step-container {
    margin-left: 0 !important;
  padding: 2rem 0 !important;
  }
  header.entry-header {
  color: white;
    letter-spacing: 2.8px;
    line-height: 3rem;
    font-size: 28px;
  }
  .step-text {
    font-size: 1.5rem;
    letter-spacing: 0.075rem;
    line-height: 1.5rem;
    margin-bottom: 0.2rem;
  }
  .circle,
  .circle-final,
  .step-desc-container {
    width: 4.3rem;
    height: 4.3rem;
  }
  .circle-text {
    width: 3rem;
    height: 3.4rem;
  }
  .circle .circle-text > * {
    margin-bottom: 0px !important;
  }
  .circle-text > :first-child {
    font-size: 1.188rem;
    line-height: 1.188rem;
    letter-spacing: 0.118rem;
  }
  .circle-text > :last-child,
  .step-desc {
    font-size: 0.875rem !important;
    letter-spacing: 0.0875rem;
    line-height: 0.875rem;
  }

  .step-desc-container {
    width: initial;
    padding: 0 1rem !important;
  }
  .line {
    height: 2.438rem;
  }
}
