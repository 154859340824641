.add-group-text-box {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bebebe;
  border-radius: 16px;
  opacity: 1;
}
.add-groups-modal-body {
  background: rgba(0, 0, 0, 0.959) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
}
.add-groups-modal-save-btn {
  background: #e60000 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 16px;
  opacity: 1;
  width: 80%;
  padding: 0.5rem;
}
.details-popup-temmemberssection {
  color: var(--unnamed-color-4a4d4e);
  text-align: left;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.details-main-header {
  color: var(--unnamed-color-4a4d4e);
  text-align: left;
  font: normal normal normal 32px/37px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.details-main-container {
  display: flex;
  flex-direction: column;
}
.details-text-header {
  text-align: left;
  font: normal normal normal 20px/23px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.details-text-value {
  text-align: left;
  font: normal normal normal 16px/27px Vodafone Rg;
  letter-spacing: 0px;
  color: #ffffffad;
  opacity: 1;
}
.details-tag {
     text-align: left;
    font: normal normal normal 16px/27px Vodafone Rg;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
  background: var(--unnamed-color-007c92) 0% 0% no-repeat padding-box;
  background: #007c92 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
      padding: 0.25rem 0.5rem;
}
.details-link {
  text-align: left;
  font: normal normal normal 18px/20px Vodafone Lt;
  letter-spacing: 0px;
  color: #e60000;
  opacity: 1;
}
.details-back-link {
  text-align: left;
  font: normal normal normal 24px/28px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.details-project-body-border {
  border-right: 1px solid var(--unnamed-color-cccccc);
  border-right: 1px solid #cccccc;
  opacity: 1;
}
