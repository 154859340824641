.asc-general-chat-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* object-fit: cover; */
  min-height: 100%;
  min-width: 100vw;

  /* Set up proportionate scaling */
  /* width: 100%;
  height: auto; */

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.asc-chat-container {
  margin: 1rem 0 1rem 1rem;
  width: 55rem;
  height: 41rem;
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #FFFFFF;
  border-radius: 16px;
  opacity: 1;
}

.asc-online-container {
  margin: 1rem 0 1rem 1rem;
  height: 41rem;
border: 3px solid var(--unnamed-color-ffffff);
background: #000000 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 3px solid #FFFFFF;
border-radius: 16px;
opacity: 1;
}
