.super-admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.super-admin-back-table-responsive {
  /* display: flex; */
  width: 100%;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive-super {
  padding: 2rem;
  display: block;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  color: black;
}

.table-fixed-super tbody {
  height: 31.25rem;
  overflow-y: auto;
  width: 100%;
  text-align: center;
}

.table-fixed-super thead,
.table-fixed-super tbody,
.table-fixed-super tr,
.table-fixed-super td,
.table-fixed-super th {
  display: block;
}

.table-fixed-super tbody td,
.table-fixed-super tbody th,
.table-fixed-super thead > tr > th {
  float: left;
  position: relative;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.table-fixed-super thead > tr > th::after {
  content: "";
  clear: both;
  display: block;
}




#table-wrapper {
  position: relative !important ;
}
#table-scroll {
  height: 150px;
  overflow: auto !important;
  margin-top: 20px;
}
#table-wrapper table {
  width: 100% !important;
}
