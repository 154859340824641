.asc-joinOuterContainer {
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* object-fit: cover; */
  min-height: 100%;
  min-width: 100vw;
  margin-left: 32%;
  /* Set up proportionate scaling */
  /* width: 100%;
  height: auto; */

  /* Set up positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.asc-joinInnerContainer {
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 3px solid #FFFFFF;
  border-radius: 16px;
  opacity: 1;
  width: 28.25rem;
  height: 100%;
  padding-top: 1rem;
}
.chat-login-header{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 32px/30px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  margin: 1rem;
}
.chat-login-room-name{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-top: 1.5rem;
}
.asc-joinInput {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #BEBEBE;
  border-radius: 10px;
  opacity: 1;font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}


.asc-heading {
  margin: 0 10rem 0 0;
  text-align: center;
  font: normal normal normal 28px/32px Vodafone Rg;
  letter-spacing: 0px;
  color: #ffffff;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 16px 16px 0px 0px;
  width: 15.625rem;
  height: 2.75rem;
  padding: 0.5rem 0.5rem;
  display: flex;
}

.asc-btn:disabled {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c1c1c1;
  color: #c1c1c1;
  border-radius: 16px;
  opacity: 1;
  width: 25.25rem;
}


.mt-20 {
  margin-top: 20px;
}
.asc-join-btns-container {
  width: 25.5rem;
  margin: 0px 1.25rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 50%;
  }
  .heading {
    /* width: 23%; */
  }
  .btn-width {
    /* width: 23%; */
  }
  .join-btns-container {
    /* width: 23%; */
  }
  .back-btns-container {
    /* width: 23%; */
    display: flex;
    flex-direction: column;
  }
  .back-btns-container > a {
    margin-bottom: 1rem;
  }
}

button:focus {
  outline: 0;
}

@media (min-width: 764px) and (max-width: 770px) {
  .joinOuterContainer {
    /* display: flex; */
  }
  .joinInnerContainer {
    width: 37%;
    display: flex;
    flex-direction: column;
    /* margin-right: 23%; */
  }
}
@media (min-width: 538px) and (max-width: 542px) {
  .joinInnerContainer {
    width: 50%;
  }
}

@media (min-width: 278px) and (max-width: 282px) {
  .joinInnerContainer {
    width: 75%;
  }
  .back-btns-container > a {
    margin-right: 0.5rem;
  }
}
/* @media (min-width: 898px) and (max-width: 282px) {
  .joinInnerContainer {
    width: 50%;
  }
} */
