.asc-photobooth {
margin-top: 4%;
/* background: white; */
/* border: 2px solid #ffffff; */
border-radius: 16px;
opacity: 0.87;
backdrop-filter: blur(6px);
-webkit-backdrop-filter: blur(6px);
/* width: 343px; */
height: 419px;
padding: 0;
margin: 0;
}
.asc-video {
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
  border: white 5px solid;
  padding: 0.25rem;
  margin-top: 3rem;
  
}
.asc-frame{
  position: absolute;
    width: 17rem;
    margin-top: 2rem;
    z-index: 500;
}
.asc-frame-bg{
  width: 20rem;
    padding-top: 1rem;
}
.asc-framename {
position: absolute;
color: #e60000;
font-weight: bolder;
text-transform: capitalize;
margin-top: 98%;
text-transform: uppercase;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 30px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
color: var(--unnamed-color-e60000);
text-align: left;
font: normal normal bold 30px/30px Vodafone Rg;
letter-spacing: 3px;
color: #E60000;
text-transform: uppercase;
opacity: 1;
}
.asc-mainframe {
  display: flex;
  align-items: center;

  flex-direction: column;
}
.asc-photobooth-button-container {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.asc-photobooth-button {
border: 3px solid var(--unnamed-color-ffffff);
background: #000000 0% 0% no-repeat padding-box;
border: 3px solid #FFFFFF;
border-radius: 10px;
opacity: 1;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
letter-spacing: var(--unnamed-character-spacing-2-4);
color: var(--unnamed-color-ffffff);
text-align: center;
font: normal normal normal 24px/30px Vodafone Rg;
letter-spacing: 2.4px;
color: #FFFFFF;
text-transform: uppercase;
opacity: 1;
}
.magic-btn{
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  border-radius: 15px 0px 0px 15px;
  opacity: 1;
  width: 55px;
  height: 48px;
    text-align: center;
    padding: 0.5rem;
    
}

.clear-btn{
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  border-radius: 0px 15px 15px 0px;
  opacity: 1;
  color: white;
  width: 55px;
  height: 48px;
  font-size: 26px;
  text-align: center;
  padding: 0.5rem;
}
#canvas {
  display: none;
}
