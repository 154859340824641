.revoke-form-container{
  background-color: rgba(0, 0, 0, 0.959);
  opacity: 1;
  min-height: 100vh;
  background-image: url("../../assets/numbersbg.png");
}
.revoke-form-title{
  text-align: center;
  font: normal normal bold 50px/100px Vodafone Rg;
  letter-spacing: 5px;
  color: #FFFFFF;
  font-size: 3.125rem;
  
}

.underline:after {
  content: '';
  display: block;
  margin-left:auto;
  margin-right: auto;
  background: #E60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;

}

.revoke-form-input-text {
  color: var(--unnamed-color-999999);
  text-align: left;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.revoke-form-alert-customize {
  padding: 0.5rem 1.25rem !important;
  border-radius: 1.25rem !important;
}
.revoke-form-input {
  border-radius: 1rem !important;
  height: 2.5rem !important;
  border: 0.1rem solid #bebebe !important;
  padding: 0.8rem !important;
}

.revoke-form-input-text {
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.revoke-form-actionBtn {
  background: none;
  border: 3px solid var(--text-primary);
  border-radius: 10px;
  color: var(--text-primary);
  margin: 13px 0px 0px 0px;
  height: 3rem;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: .15rem;
}


.modal-question{
  color: #FFF;
  
  
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.revoke-form-text{
  color: #FFF;
    
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.revoke-form-urgent-text{
  color: #E60000;
  
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.revoke-submitbtn{
  border-radius: 10px;
    background: #E60000;
    color: #FFF;
    width: 80%;
    padding: 1rem 0;
    border: none;
    color: #FFF;
    
      font-style: normal;
      font-weight: 400;
      line-height: normal;
}
.revoke-cancelbtn {
  border-radius: 10px;
  background: #FFF;
  color: #e60000;
  width: 80%;
  padding: 1rem 0;
  border: none;

  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.revoke-link {
  color: #e60000;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.revoke-link:hover {
  color: #cc0000;
  text-decoration: underline;
}
/* * {
    --color-grey1: #4a4d4e;
    --color-red1: #e60000;
  }
  .revoke-pageContainer {
    background: url("../../assets/mask.svg") no-repeat center center fixed;
    background-size: cover;
    background-color: #860101;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .revoke-form-pageContainer {
    background: url("../../assets/mask.svg") no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0px;
    left: 0px;
    background-color: #860101;
  }
  
  .revoke-container {
    width: 100%;
    height: 70%;
    color: #4a4d4e;
    border: white 2px solid;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    margin: 4rem 2rem 3rem 2rem;
    padding: 2rem;
    text-align: center;
    justify-content: center;
  }
  
  .revoke-body-title {
    color: var(--color-grey1);
    text-align: center;
    font: normal normal normal 35px/55px Vodafone Rg;
    letter-spacing: 0px;
    color: #4a4d4e;
    opacity: 1;
  }
  
  .revoke-form-body-subtitle {
    color: var(--color-grey1);
    text-align: center;
    font: normal normal normal 30px/30px Vodafone Rg;
    letter-spacing: 0px;
    opacity: 1;
  }
  
  .revoke-body-actionBtn1 {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 80%;
    height: 3rem;
    margin-top: 4rem;
  }
  .revoke-body-actionBtn2 {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 80%;
    height: 3rem;
    margin-top: 1rem;
  }
  
  .revoke-body-actionBtn1 {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 80%;
    height: 3rem;
    margin-top: 4rem;
  }
  .revoke-body-actionBtn2 {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 80%;
    height: 3rem;
    margin-top: 1rem;
  }
  
  .revoke-form-container {
    width: 100%;
    height: auto;
    color: #4a4d4e;
    border: white 2px solid;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    margin: 4rem 2rem 3rem 2rem;
    padding: 2rem;
    text-align: center;
    justify-content: center;
  }
  
  .revoke-form-alert-customize {
    padding: 0.5rem 1.25rem !important;
    border-radius: 1.25rem !important;
  }
  .revoke-form-input {
    border-radius: 1rem !important;
    height: 2.5rem !important;
    border: 0.1rem solid #bebebe !important;
  }
  
  .revoke-form-input-text {
    color: var(--unnamed-color-999999);
    text-align: left;
    letter-spacing: 0px;
    color: #999999;
    opacity: 1;
  }
  .revoke-form-actionBtn {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    width: 100%;
    height: 3rem;
  }
  .revoke-form-checkBox {
    margin-top: 1rem;
  }
  .revoke-form-checkBox-2 {
    text-align: left;
    color: #4a4d4e;
    opacity: 0.6;
    font-size: 0.9rem;
    margin-top: 1.2rem;
  }
  .revoke-form-checkBox-3 {
    text-align: left;
    color: #4a4d4e;
    opacity: 0.6;
    font-size: 0.9rem;
    margin-top: 2.5rem;
  }
  
  .revoke-forget-form-actionBtn {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 100%;
    height: 3rem;
  }
  .revoke-forget-form-actionBtn-email {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    width: 100%;
    height: 3rem;
  }
  
  .revoke-change-account {
    color: red;
    text-align: right;
    font-size: 0.8rem;
    border: none;
    background-color: white;
  }
  
  .revoke-success-btn {
    background-color: var(--color-red1);
    border: 1px solid var(--color-red1);
    border-radius: 1rem;
    color: white;
    margin-bottom: 1rem;
    width: 100%;
    height: 3rem;
    margin-top: 2rem;
  }
   */