.third-section-div {
    width: 100%;
    padding: 0 3rem 0 3rem;
    background-color: black;



}

.third-page-main-content {
    width: 100%;
    display: flex;

    flex-direction: column;
    position: relative;
        top: -4rem;
}

.frame-top-design {
    justify-content: space-between;
    display: flex;
}

.righttopframe {
    background-position: right;
    background-image: url("../../assets/framesides/topright.svg");
    background-repeat: no-repeat;
    width: 25px;
        height: 66px;
}

.lefttopframe {
 
    background-position: left;
    background-image: url("../../assets/framesides/topleft.svg");
    background-repeat: no-repeat;
    width: 25px;
        height: 66px;
}

.frame-bottom-design {
    justify-content: space-between;
    display: flex;
   
}

.rightbottomframe {
    background-position: right;
    background-image: url("../../assets/framesides/bottomright.svg");
    background-repeat: no-repeat;
    height: 7rem;
width: 4%;
}

.leftbottomframe {
    width: 4%;
    background-position: left;
    background-image: url("../../assets/framesides/bottomleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
 
}

.third-section-main-title {
    padding: 32px 0;
color: #FFF;

    text-align: center;
    /* h1 */
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 9.6px;
    text-transform: uppercase;
}
.third-section-details-box{

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #FF3131;
    margin: 24px 20px 0 0;
    width: 23.65%;
    height: 368px;
        position: relative;
}
.third-section-details-boxby4 {

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #FF3131;
    margin: 24px 0 0 0;
    width: 23.65%;
    height: 368px;
    position: relative;

}
.third-section-details-box-img {
    display: flex;
    width: 100%;
        height: 366px;
            border-radius: 5px;
                border: 1px solid #FF3131;
            object-fit: cover;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 2rem;
}
.third-section-details-box-details {
position: absolute;
    bottom: 0.25rem ;
    left: 12px;
}
.third-section-details-beforehover{
        display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
         
          
}
.naming-tags{
position: absolute;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    height: rem;
    bottom: 0;}
.third-section-details-box-name{
    color: #FFF;
    
        
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
}
.third-section-details-box-title {
    color: #FFF;
    
        
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
            max-width: 15rem;
                margin-top: 2px;
}
.sloganone-div{
    color: #FFF;
    
        
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
}
.slick-ss{
    height: 1rem;
    align-self: flex-end;
    padding-bottom: 1.5rem;
}
.main-d{
    display: flex;
    align-items: center;
    flex-direction: row;
    padding:0 1rem;
}
/* @media (min-width: 992px)  {
    .third-section-details-box {width: 470px;
        height: 514px;}
    .third-section-details-box-img {height: 514px;}
        .naming-tags {width: 469px;
            height: 350px;
            top: -30%;}
} */

@media only screen and (max-width: 767.98px) {
    .third-section-main-title{
        color: #FFF;
        
            text-align: center;
            /* h1Mobile */
            font-size: 22px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.2px;
            text-transform: uppercase;
    }
.third-section-details-box {
    width: 46.5%;
    height: 248px;
            margin: 24px 7px 0 0;

    }
.third-section-details-boxby4{
width: 46.5%;
    height: 248px;
        margin: 24px 7px 0 0;
}
    .third-section-details-box-img {
    object-fit: cover;
    height: 50%;
    height: 130px;
    }

    .naming-tags {
        top: 48%;
        width: 100%;
    }
        .slick-ss {height: fit-content;}
                .main-d {}
                .third-section-details-beforehover{
width: 100%;
                }
                .third-section-details-box-title{
                    color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                }
                .third-section-details-box-name{
                    color: #FFF;
                        /* font-family: Vodafone; */
                        font-size: 16px;
                        font-style: normal;
                        line-height: normal;
                }
        .third-section-details-box-details{
            position: absolute;
                top: 6px;
                left: 6px;
        }
        .sloganone-div{
            color: #FFF;
                text-align: center;
                font-size: 8px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.4px;
        }
}
        