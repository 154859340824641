.table-head-row th,
.table-body-row td {
  color: white;
}

.panel-judge-container .Container-NavBar{
  background-color: #191818;
}

.panel-judge-container .coaches-pageContainer{
  background: unset;
}