* {
  --color-grey1: #4a4d4e;
  --color-red1: #e60000;
}

.btn-check-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.panelRoom-container {
  display: flex;
  flex-wrap: wrap;
}

.back-span {
  margin-inline-start: 1rem;
  color: white;
}

.border-bottom.container {
  padding-left: unset !important;
}
.logo {
  right: 48%;
}

.redBgc {
  padding-left: unset !important;
}

.room {
  border-radius: 1rem;
  background-color: white;
  color: black;
  padding: 1rem;
  display: block;
  align-items: center;
  justify-content: center;
  width: 31.893%;
  margin-top: 0.5rem;
  padding: 1rem;
  margin-inline-end: 1rem;
  height: fit-content;
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
}
.room-container-header,
.room-container-body,
.room-container-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.room-container-subheader{
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
 width: 100%;
 justify-content: space-between;
}
.searchbox-room{
  border: 2px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
  color: white;
  width: 16.5rem;
  height: 1.85rem;
}
.searchbox-room-btn {
  border: 2px solid var(--unnamed-color-ffffff);
  background: 2px solid #FFFFFF;
    border-radius: 10px;
    position: absolute;
    margin-left: 14.65rem;
    height: 1.85rem;
    cursor: pointer;
    z-index: 1;
}
.room-sort-btn{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
  align-self: flex-end;
  margin-left: 65%;
}
@media only screen and (max-width: 500px) {
  .searchbox-room-btn {
    display: block;
    border: 2px solid var(--unnamed-color-ffffff);
    background: 2px solid #FFFFFF;
    border-radius: 10px;
    position: absolute;
    margin-left: 14.65rem;
    height: 1.85rem;
    cursor: pointer;
    z-index: 1;
  }
  
  .room-sort-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 3px solid var(--unnamed-color-ffffff);
    background: #000000 0% 0% no-repeat padding-box;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    align-self: flex-end;
    margin-left: 35%;
  }
  .room{
    width:90%;
    margin-left:0;
    margin-right: 0;
  }
}
.searchbox-room-icon-btn{
  color: #000000 0% 0% no-repeat padding-box;
}

.links-div {
  overflow: auto;
  min-height: 2rem;
}
.btns-div {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.internal-btns-div{
  display: flex;
  justify-content: space-between;
}
.internal-btns-header{
  margin-bottom: 0;
  margin-right: 4.5rem;
  width: 100%;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.coaches-pageContainer {
  background-color: rgba(0, 0, 0, 0.959);
  background-image: url("../../../assets/numbersbg.png");
  min-height: 100vh;
}
.header-support{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-5);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  margin-right: 7.5rem;
  font: normal normal bold 50px/100px Vodafone Rg;
  letter-spacing: 5px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  
}
.coaches-redLine{
 
text-align: center;
    margin-left: 35%;
    margin-bottom: 3rem;
width: 191px;
height: 3px;

/* UI Properties */
background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
background: #E60000 0% 0% no-repeat padding-box;
border-radius: 8px;
opacity: 1;
}
.section-container {
  padding: 2rem;
  display: block;
  width: 100%;
}

.room-container-header {
  color: var(--color-grey1);
}
.room-container-header .room-title {
  font-size: 28px;
}
.room-container-header .room-welcome {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 32px/30px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.room-container-body span {
  font-size: 16px;
  color: var(--color-grey1);
}

.btns-div .join {
border: 2px solid var(--unnamed-color-ffffff);
border: 2px solid #FFFFFF;
border-radius: 10px;
opacity: 1;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
letter-spacing: var(--unnamed-character-spacing-2-4);
color: var(--unnamed-color-ffffff);
text-align: center;
font: normal normal normal 24px/30px Vodafone Rg;
letter-spacing: 2.4px;
color: #FFFFFF;
text-transform: uppercase;
background-color: transparent;
width: 100%;
margin-top: 0.5rem;
margin-bottom: 1rem;
}
.btns-div .join:disabled {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-4a4d4e);
  text-align: center;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #4A4D4E;
  text-transform: uppercase;
  background: var(--unnamed-color-707070) 0% 0% no-repeat padding-box;
  border: 2px solid var(--unnamed-color-4a4d4e);
  background: #707070 0% 0% no-repeat padding-box;
  border: 2px solid #4A4D4E;
  border-radius: 10px;
  opacity: 1;
}

.room-container-btns .eval {
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 0rem;
  border-radius: 16px;
  background-color: white;
  color: var(--color-red1);
  border: 1px solid var(--color-red1);
}
.switch {
    position: relative;
      display: inline-block;
      width: 6.5rem;
      height: 36px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
      background-color: transparent;
        border: 1px white solid;
}

input:focus+.slider {
    background-color: transparent;
      border: 1px white solid;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}