.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  border-radius: 16px;
  padding: 1rem;
  background-color: var(--text-primary);
}
.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.file-input-container-text {
  color: #e60000;
}
.file-name {
  color: #4a4d4e;
}
.mycvgrodeypdf {
  border: unset;
  background: unset;
}
.change-file-btn-text {
  color: #e60000;
  text-decoration: underline;
}
