
.code-input {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.code-inputs {
  display: flex;
  justify-content: start;
  align-items: center;
}
.code-inputs input {
  margin-inline-end: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 35px;
  width: 25px;
  border-radius: 10px;
  border: 0.0625rem solid #bebebe;
}
.code-inputs input:focus {
  outline: none;
}

