.reg-alert {
  background-color: unset !important;
  color: #B3261E !important;
  border: unset !important;
  padding: unset !important;
      max-height: 3rem;
}
.scrolled {
  overflow-y: auto;
  height: 37rem;
  overflow-x: hidden;
}
.scrolled::-webkit-scrollbar-thumb {
  background: darkgray;
}
.scrolled::-webkit-scrollbar {
  width: 0.5rem !important;
  background-color: #191818 !important;
  border-radius: 10px;
}

#guardianform {
  height: 100%;
  width: 100%;
  margin: 0px !important;
  min-height: calc(100vh - 10rem);
}

/*align form container with nav */
.gaurdian-form-alignment {
  padding-left: 4rem !important;
}

.title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-4);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 40px/40px Vodafone Rg;
    letter-spacing: 4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 1.5rem;
}

.required {
  top: 0rem;
  left: 5.1875rem;
  width: 0.5rem;
  height: 1.375rem;
  text-align: left;
  font: normal normal normal 1.25rem/1.4375rem Vodafone Rg;
  letter-spacing: 0rem;
  color: white;
}
.label-format {
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.text-box,.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #bebebe;
  border-radius: 4px;
  opacity: 1;
  width: 100%;
  height: 2.5rem;
  padding: 1rem;
}
.text-box-error {

  border: 0.1rem solid #B3261E;

border-radius: 4px;
  opacity: 1;
  width: 100%;
  height: 2.5rem;
  padding: 1rem;
}
.svg-icon-container {
    position: inherit;
      bottom: 20%;
      left: 89%;
      pointer-events: none;
  /* Ensure the icon does not interfere with text input */
}

.hint {
  font: normal normal normal 0.8rem Vodafone Rg;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  margin-top: 0.5rem;
  padding-left: 0.4rem;
}
.hint li{
  font-size: 14px;
}
.date-box {
  width: 100%;
  height: 3rem;
  background: 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #bebebe;
  border-radius: 10px;
  opacity: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 2.5rem;
}
.Rectangle {
  width: 11.4375rem;
  height: 3rem;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 1.25rem/1.4375rem Vodafone Rg;
  letter-spacing: 0rem;
  color: #ffffff;
  position: absolute;
  right: 11%;
  top: 61.5rem;
  cursor: pointer;
}

.Rectangle-Back {
  width: 2.3125rem;
  height: 1.375rem;
  color: #e60000;
  text-align: left;
  text-decoration: underline;
  font: normal normal normal 1.25rem/1.4375rem Vodafone Rg;
  top: 62.3125rem;
  position: absolute;
  right: 30%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .egypt-stepper-container {
    padding: 0 15px !important;
    padding-left: unset !important;
  }
}

@media (max-width: 991.98px) {
  #guardianform {
    padding-left: 0rem !important;
  }
  .gaurdian-form-alignment {
    padding: 0px !important;
  }
}
