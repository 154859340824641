.announ {
  border-radius: 1rem;
  background-color: white;
  color: black;
  padding: 1rem;
  display: block;
  align-items: center;
  justify-content: center;
  width: 31.893%;
  margin-top: 0.5rem;
  padding: 1rem;
  margin-inline-end: 1rem;
  height: fit-content;
  border: 3px solid var(--unnamed-color-ffffff);
  background: #000000 0% 0% no-repeat padding-box;
  border: 3px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
}

.announ-container-header,
.announ-container-body,
.announ-container-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.announcments-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.announs-pageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: url("../../../assets/mask.svg") no-repeat center center fixed;
  background-size: cover;
  background-color: #860101;
  width: 100%;
  min-height: 100%;
}
.announ-container-header {
  color: var(--color-grey1);
}
.announ-container-header .announ-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-3-2);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 32px/30px Vodafone Rg;
    letter-spacing: 3.2px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}
.announ-container-header .announ-body {

}

.announ-container-body span {
 
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 18px/20px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.card-border{
  margin-top: 1rem;
  width: 98%;
  height: 0px;
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  opacity: 1;
}
.card-footer{
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  margin-top: 1rem;
}
.footer-date{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-999999);
  text-align: left;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  margin-right: 2.5rem;
}
.footer-Delete{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-999999);
  text-align: right;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
  cursor: pointer;
}
.announ-container-btns .eval {
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 0rem;
  border-radius: 16px;
  background-color: white;
  color: var(--color-red1);
  border: 1px solid var(--color-red1);
}
@media only screen and (max-width: 500px) {
  .searchbox-room-btn {
    display: block;
    border: 2px solid var(--unnamed-color-ffffff);
    background: 2px solid #FFFFFF;
    border-radius: 10px;
    position: absolute;
    margin-left: 14.65rem;
    height: 1.85rem;
    cursor: pointer;
    z-index: 1;
  }

  .room-sort-btn {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    border: 3px solid var(--unnamed-color-ffffff);
    background: #000000 0% 0% no-repeat padding-box;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    align-self: flex-end;
    margin-left: 35%;
  }

  .announ {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
  }
}