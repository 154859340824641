.region-box {
    display: flex;

    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 8px);
    flex-shrink: 0;
    border-radius: var(--16, 16px);
    background: var(--new-palette-default-monochrome-monochrome-2, #F2F2F2);
    padding: 1rem;

}

.region-box-chart {
    min-height: 15rem;
    display: flex;

    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--8, 8px);
    flex-shrink: 0;
    border-radius: var(--16, 16px);
    background: var(--new-palette-default-monochrome-monochrome-2, #F2F2F2);
    padding: 1rem;
    align-items: center;
    justify-content: center;
}

.region-box-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}