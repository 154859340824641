.region-pageContainer {
  top: 0px;
  left: 0px;
  background: var(--background) url("../../../assets/Pattern.svg") 0% 0% no-repeat padding-box;
  min-height: 100vh;
  background-size: cover !important;
  overflow-y: auto;
}
.region-body-container {
  min-height: calc(100vh - 4.688rem);
  /* align-content: center; */
}
.region-body {
  width: 100%;
  text-align: center;
  margin-top: calc(33vh - 6.25rem);
}

.region-text-1,
.region-text-2 {
  color: var(--color-grey1);
  text-align: center;
  font-family: Vodafone Rg;
  text-transform: uppercase;
  color: var(--text-primary);
  margin: 0px;
}
.region-text-1 {
  font-size: 3.125rem;
  letter-spacing: .313rem;
  line-height: 6.25rem;
  font-weight: bold;
}

.region-text-2 {
  font-size: 1.5rem;
  letter-spacing: .15rem;
  line-height: 1.875rem;
}
.regionBtn {
  border: 1px solid var(--gray);
  border-radius: 1rem;
  background-color: var(--text-primary);
  width: 25rem;
  height: 3rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: .15rem;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
  position: relative;
  text-align: left;
  padding-left: 1rem;
}
.dropdown-menu {
  /* min-width: 20rem !important; */
  /* margin: 0.125rem 0.6rem 0 !important; */
  text-transform: uppercase;
  width: 25rem;
}

.form-check-container {
  display: flex;
  justify-content: center;
  padding: 0px 32.5% 0px 30.5%;
  margin: 1.5rem 0 1.5rem 0;
}
.form-check-container > * {
  min-width: 17.5rem;
}
.form-check-container label {
  color: var(--text-primary);
  font-size: 1rem;
}

.form-check-container label a {
  color: var(--text-secondary);
  display: inline-block;
}
.form-check-container label a::first-letter {
  text-transform: uppercase !important;
}

.nextBtn {
  width: 11.438rem;
  height: 3rem;
  background-color: initial;
  border-radius: 1rem;
  border: 3px solid var(--text-primary);
  text-align: center;
  color: var(--text-primary);
  text-transform: uppercase;
  letter-spacing: .15rem;
  margin-bottom: 2rem;
}

.dropdown-toggle::after {
  font-size: 0px;
  background: url("../../../assets/arrow-down.svg") 0% 0% no-repeat padding-box;

  width: 1.938rem;
  height: 1rem;
  position: absolute;
  right: .625rem;
  top: 1rem;
}
.form-check-box{
  max-width: 46rem;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
}
@media (max-width: 600px) {
  .region-body {
    /* margin-top: calc(33vh - 180px); */
  }
  .region-text-1 {
    font-size: 1.75rem;
    letter-spacing: .175rem;
    line-height: 2.375rem;
    margin-bottom: 1.5rem;
  }

  .region-text-2 {
    font-size: 1.5rem;
    line-height: 2rem;

  }
  .regionBtn {
    width: 20.75rem;
    height: 3rem;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .dropdown-toggle::after {
    top: 1.172rem;
    background-size: 75% 50%;
  }
  .dropdown-menu {
    width: 20.75rem;
  }
  .nextBtn {
    width:6.25rem;
    height: 2.5rem;
    font-size: 1.125rem;
    letter-spacing: .113rem;
  }
}
