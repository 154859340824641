.prizes-page-container{
  padding: 2rem;
  padding-top: 0 !important;
  display: flex;
  flex-wrap: wrap;
  /* background: url(/static/media/mask.42e30752.svg) no-repeat center center fixed; */
  background-size: cover;
  width: 100%;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  min-height: 100vh;
background-color:#191818;
  
}

.prizes-card__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8.6rem;
  margin: 0px;
  margin-bottom: 1rem;
}
.top-three-prizes-container{
  padding-top:1rem ;
  padding-bottom: 3rem;
  border-bottom:2px solid var(--text-primary);
  align-items: center;
}
.other-prizes-container{
margin-top: 4.4rem;
justify-content: space-between;
}
.prizes-card__container{
  width: 100%;
  height: 100%;
  background-color: var(--text-primary);
  border-radius: .6rem;
  padding: 1rem;
}
.prizes-card__wrapper:nth-child(odd){
  padding-right: .5rem !important ;
}
.prizes-card__wrapper:nth-child(even){
  padding-left: .5rem !important ;
}




/**********media-queries***********/

@media (max-width:767.98px) {
  .prizes-card__wrapper:nth-child(odd){
    padding-right: 0rem !important ;
  }
  .prizes-card__wrapper:nth-child(even){
    padding-left: 0rem !important ;
  }
}
@media (max-width:991.98px) {
  
.prizes-card--vertical {
  padding-top: 1rem;
  padding-bottom: 1rem;
  
}
  .prizes-card-border{
    border-left: 0px;
    border-right: 0px;
    
      border-top: 2px solid var(--text-primary);
      border-bottom: 2px solid var(--text-primary) ;
    
  }
}