.project-details {
  padding: 5%;
  padding-bottom: 3%;
}
.form-header {
  height: 5rem;
 
}


.alertDanger {
  width: 40% !important;
}

.tag {
  color: #707070;
  margin-inline-start: 1rem;
}
.content {
  color: white;
  background-color: #707070;
  padding: 0.2em 0.6em 0.3em;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
}
.rm-lnk {
  color: white;
  background-color: #707070;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  cursor: pointer;
  margin-left: -5px;
  padding: 0.2em 0.3em 0.3em;
}
.rm-link:hover {
  background-color: #555555;
  cursor: pointer;
}

.form-footer {
  display: flex;
  justify-content: space-around;
}

.btn-secondary,
.btn-success {
  padding: 1% 5% 1% 5% !important;
}

.add,
.minus {
  border: unset;
}
