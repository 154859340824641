@font-face {
  font-family: Vodafone Lt;
  src: url('../src/assets/fonts/VodafoneLt.ttf');
}
@font-face {
  font-family: Vodafone RG;
  src: url("../src/assets/fonts/VodafoneRg.ttf");
}

@font-face {
  font-family: Vodafone RG;
  src: url("./assets/fonts/VodafoneRgBd.ttf");

  font-weight: 700;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span,
button {
  font-family: "Vodafone RG" !important;
}
.fa{
  font-family: FontAwesome !important;
}
#hackathon-agenda {
  padding: 0 !important;
  z-index: 9000000000000;
}

#hackathon-agenda .modal-dialog {
  width: 100%;
  max-width: none;
  min-height: 100vh !important;
  margin: 0 !important;
      position: absolute !important;
      padding: 0;
      overflow-y: auto;
     
}

#hackathon-agenda .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  background: transparent linear-gradient(180deg, #000000 0%, #2C2C2C 100%) 0% 0% no-repeat padding-box !important;
}

#hackathon-agenda .modal-body {
  overflow-y: auto;
}


#root {
  height: 100vh;
  /* overflow-y: auto; */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.transition-asc-img {
  /* transition: all 0.2s ease-in-out;
  position: absolute; */
  cursor: pointer;
}
.transition-asc-img:hover {
  transform: scale(1.03);
}
.asc-cards-flip-container {
  min-height: 92.25vh;
  padding: 0;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
@media (min-width: 320px) and (max-width: 480px) {
  .asc-cards-flip-container {
    height: 200vh;
    padding: 0;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    overflow: hidden;
  }
  .session-config-card {
    margin: 0;
  }
}
.session-config-card {
  margin: 5rem;
}
table {
  table-layout: fixed;
  word-wrap: break-word;
}
.nav-link {
  cursor: pointer;
}

.single-title {
  color: white;
}

:root {
  --background: #191818;
  --text-primary: #ffff;
  --text-secondary: #e60000;
  --checked: #007b91;
  --gray: #bebebe;
  --gray-muted: #999999;
  --dark-gray: #4a4d4e;
}
.switch.btn.off {
  border: 1px solid var(--unnamed-color-ffffff) !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 10px !important;
  opacity: 1;
  background-color: transparent !important;
  height: 0.25rem;
}
.switch.btn.on {
  border: 1px solid var(--unnamed-color-ffffff) !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 10px !important;
  opacity: 1;
  background-color: 1px solid #FFFFFF !important;
  height: 0.25rem;
}
.switch-off{
      background-color: transparent !important;
      color: white !important;
}
.switch-on {
  background-color: white !important;
  color: black !important;
}
.asc-joinInput {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
  background: #FFFFFF 0% 0% no-repeat padding-box  !important;
  border: 1px solid #BEBEBE  !important;
  border-radius: 10px  !important;
  opacity: 1;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0)  !important;
  text-align: left  !important;
  font: normal normal normal 24px/30px Vodafone Rg  !important;
  letter-spacing: 0px  !important;
  color: #000000  !important;
 
}
.asc-knowwhats-going-on-btn {
  border: 2px solid var(--unnamed-color-ffffff) !important;
  background: #000000 0% 0% no-repeat padding-box  !important;
  border: 2px solid #FFFFFF  !important;
  border-radius: 10px  !important;
 
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg)  !important;
  letter-spacing: var(--unnamed-character-spacing-2-4)  !important;
  color: var(--unnamed-color-ffffff)  !important;
  text-align: center  !important;
  font: normal normal normal 24px/30px Vodafone Rg  !important;
  letter-spacing: 2.4px  !important;
  color: #FFFFFF  !important;
  text-transform: uppercase  !important;
  opacity: 1;
}
.asc-knowwhats-going-on-btn:disabled {
  background: var(--unnamed-color-707070) 0% 0% no-repeat padding-box !important;
border: 2px solid var(--unnamed-color-4a4d4e) !important;
background: #707070 0% 0% no-repeat padding-box !important;
border: 2px solid #4A4D4E !important;
border-radius: 10px !important;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg) !important;
letter-spacing: var(--unnamed-character-spacing-2-4) !important;
color: var(--unnamed-color-4a4d4e) !important;
text-align: center !important;
font: normal normal normal 24px/30px Vodafone Rg !important;
letter-spacing: 2.4px !important;
color: #4A4D4E !important;
text-transform: uppercase !important;}
.css-tj5bde-Svg{
  background: #000000 0% 0% no-repeat padding-box !important;
  color: white !important;
  border-radius: 50%;
}
.css-1s2u09g-control{
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #BEBEBE !important;
  border-radius: 10px !important;
}

.an-simple-select__option.an-simple-select__option {
  background-color: white;
  color: #191818;
}
.alert-danger{
  font-size: 18px !important;
    padding: 0.5rem !important;

        color: #e60000 !important;
        background-color: transparent !important;
        border-color: transparent !important;
  
}
.react-tags-wrapper{
  width: 100% !important;
}
.slick-next:before,
.slick-prev:before {
  display: none;
}
p{
      word-break: break-all;
}