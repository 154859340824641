/* #mySidenav{
    position: relative;
    top:35%;
    z-index: 999;
}
#mySidenav a {
    position: absolute;
    right: -80px;
    transition: 0.3s;
    padding: 15px;
    width: 100px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius: 5px 0px 0px 5px;
    cursor: pointer;
  }
  
  #mySidenav a:hover {
    right: 0;
  }
  
  #about {
    top: 20px;
    background-color: #555
  }
  
  #blog {
    top: 80px;
    background-color: #555
  } */

/* #projects {
    top: 140px;
    background-color: #555
  }
  
  #contact {
    top: 200px;
    background-color: #555
  } */

/* 
#mySidenav a {
  background-color: #e60000;
  position: fixed;
  right: -40px;
   transition: 0.8s; 
  width: 100px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  transform: rotate(270deg);
  transform-origin: 10% 10%;
   border-radius: 5px; 
  cursor: pointer;
  border: 1px solid #6d0000;
}
#mySidenav a:hover {
  background-color: #6d0000;
}
#superAdmin {
  font-size: 11px !important;
  bottom: 470px;
  background-color: #555;
  text-align: center;
}
#romnia {
  bottom: 370px;
  background-color: #555;
  text-align: center;
}
#india {
  bottom: 270px;
  background-color: #555;
  text-align: center;
}
#egypt {
  bottom: 170px;
  background-color: #555;
  text-align: center;
}
#participant {
  bottom: 0px;
  background-color: #555;
  text-align: center;
} */
#mySidenav {
  position: relative;
  top: 35%;
  z-index: 999;
}
a {
  color: inherit;
}

.menu-item,
.menu-open-button {
  z-index: 1;
  background: #eeeeee;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  position: absolute;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform ease-out 200ms;
  transition: -webkit-transform ease-out 200ms;
  transition: transform ease-out 200ms;
  transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.lines {
  position: absolute;
  color: white;
}

.line-1 {
  -webkit-transform: translate3d(0, -8px, 0);
  transform: translate3d(0, -8px, 0);
}

.line-2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.line-3 {
  -webkit-transform: translate3d(0, 8px, 0);
  transform: translate3d(0, 8px, 0);
}

.menu-open:checked + .menu-open-button .line-1 {
  -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.menu-open:checked + .menu-open-button .line-2 {
  -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.menu-open:checked + .menu-open-button .line-3 {
  -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
  transform: translate3d(0, 0, 0) rotate(-45deg);
}

.menu {
  position: fixed;
  /* top: 0; */
  /* left: 0; */
  right: 1rem;
  width: 80px;
  height: 80px;
  bottom: 1rem;
  /* text-align: center; */
  box-sizing: border-box;
  font-size: 26px;
}

/* .menu-item {
   transition: all 0.1s ease 0s;
} */

.menu-item:hover {
  background: #eeeeee;
  color: #e60000;
}

.menu-item:nth-child(3) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(4) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(5) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(6) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(7) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(8) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-item:nth-child(9) {
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
}

.menu-open-button {
  z-index: 2;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
}

.menu-open-button:hover {
  -webkit-transform: scale(1.2, 1.2) translate3d(0, 0, 0);
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  -webkit-transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
  right: 5rem;
  bottom: 6.5rem;
}

.menu-open:checked ~ .menu-item {
  -webkit-transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);
  right: 5rem;
  bottom: 6.5rem;
}

.menu-open:checked ~ .menu-item:nth-child(3) {
  transition-duration: 180ms;
  -webkit-transition-duration: 180ms;
  -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
  transform: translate3d(0.08361px, -104.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(4) {
  transition-duration: 280ms;
  -webkit-transition-duration: 280ms;
  -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
  transform: translate3d(90.9466px, -52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(5) {
  transition-duration: 380ms;
  -webkit-transition-duration: 380ms;
  -webkit-transform: translate3d(90.9466px, 52.47586px, 0);
  transform: translate3d(90.9466px, 52.47586px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(6) {
  transition-duration: 480ms;
  -webkit-transition-duration: 480ms;
  -webkit-transform: translate3d(0.08361px, 104.99997px, 0);
  transform: translate3d(0.08361px, 104.99997px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(7) {
  transition-duration: 580ms;
  -webkit-transition-duration: 580ms;
  -webkit-transform: translate3d(-90.86291px, 52.62064px, 0);
  transform: translate3d(-90.86291px, 52.62064px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(8) {
  transition-duration: 680ms;
  -webkit-transition-duration: 680ms;
  -webkit-transform: translate3d(-91.03006px, -52.33095px, 0);
  transform: translate3d(-91.03006px, -52.33095px, 0);
}

.menu-open:checked ~ .menu-item:nth-child(9) {
  transition-duration: 780ms;
  -webkit-transition-duration: 780ms;
  -webkit-transform: translate3d(-0.25084px, -104.9997px, 0);
  transform: translate3d(-0.25084px, -104.9997px, 0);
}

.blue {
  background-color: #669ae1;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.blue:hover {
  color: #669ae1;
  text-shadow: none;
}

.green {
  background-color: #70cc72;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.green:hover {
  color: #70cc72;
  text-shadow: none;
}

.red {
  background-color: #fe4365;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.red:hover {
  color: #fe4365;
  text-shadow: none;
}

.purple {
  background-color: #c49cde;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.purple:hover {
  color: #c49cde;
  text-shadow: none;
}

.orange {
  background-color: #fc913a;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.orange:hover {
  color: #fc913a;
  text-shadow: none;
}

.lightblue {
  background-color: #62c2e4;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.lightblue:hover {
  color: #62c2e4;
  text-shadow: none;
}

.credit {
  margin: 24px 20px 120px 0;
  text-align: right;
  color: #eeeeee;
}

.credit a {
  padding: 8px 0;
  color: #c49cde;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.credit a:hover {
  text-decoration: underline;
}
