.fifth-section-div {
    width: 100%;
    padding: 2rem 3rem 0 3rem;
    background-color: black;
/* height: 100%; */


}
.bg{
    background-image: url("../../assets/fromhome.gif");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fifth-page-main-content {
    /* background-image: url("../../assets/fromhome.gif"); */
    width: 100%;
    display: flex;

    flex-direction: column;
    
}

.frame-top-design {
    justify-content: space-between;
    display: flex;
        z-index: 2;
            position: relative;
            top: 5.5rem;
}

.righttopframe {
    background-position: right;
    background-image: url("../../assets/framesides/topright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.lefttopframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/topleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.frame-bottom-design {
    justify-content: space-between;
    display: flex;
    position: relative;
        bottom: 5.5rem;
}

.rightbottomframe {
    background-position: right;
    background-image: url("../../assets/framesides/bottomright.svg");
    background-repeat: no-repeat;
    height: 7rem;
    width: 50%;
}

.leftbottomframe {
    width: 50%;
    background-position: left;
    background-image: url("../../assets/framesides/bottomleft.svg");
    background-repeat: no-repeat;
    height: 7rem;
}

.fifth-section-main-title {
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: #FFF;

    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}
.fifth-page-main-content-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.video-div {
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 35%;
    justify-content: center;
        display: flex;
        padding-top: 45px;
}

.sectionfive-video {
    display: flex;
   

    flex-shrink: 0;
}
.main-div{
    width: 50%;
}
.secondsection-description{
    text-align: center;
        justify-content: center;
        display: flex;
    color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
}
@media only screen and (max-width: 991.98px) {
    
.frame-top-design,.frame-bottom-design{
    display: none;
}
.fifth-section-main-title{
    color: #FFF;
    
        text-align: center;
        /* h1Mobile */
        font-size: 22px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 2.2px;
        text-transform: uppercase;
}
.secondsection-description{
padding: 0 2rem;
    color: #FFF;
    
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
}
.main-div {
    width: 100%;
  margin-bottom: 1rem;
}
    

    .video-div {
        width: auto;

    }

    .sectionfive-video {
        display: flex;
        width: 100%;
        flex-shrink: 0;
    }}