
.accordion-button {
    width: 750px ;
    background-color: rgb(0 0 0 / 0%) ;
    text-align: left;
    font: normal normal normal 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    border: unset;
    padding-top: 1.3rem;
    padding-left: 1.6rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
        width: 100%;
}

.accordion-collapse {
    /* width: 750px;
    padding: 1.5rem;
    margin-top: -2%; */
    text-align: left;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    opacity: 1;
    color: #FFFFFF;
 
}


.pull-left{
    float: left;
}
.pull-right{
    float: right;
}
.accordion-body{
    text-align: left;
    font: normal normal nosrmal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    opacity: 1;
    color: #FFFFFF;
    border-top: 1px solid white;
     padding-top: 3%; 
    font-size: 1.5rem;
}
.accordion-body>p{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: left;
        font: normal normal normal 24px/32px Vodafone Lt;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
}
.accordion-item{
    border: 2px solid white;
    border-radius: 16px;
}

.accordion-header::after{
    display: inline-block;
    background-image:url("../../assets/angle-up-solid.svg");
  
}
@media (min-width:720px)  {
    .accordion-collapse{
        width: 95%; 
        padding: unset;
    }
}

@media (max-width: 550px) {
   .accordion-body{
       font-size:1rem;
   }
   .accordion-collapse{
       width: 95%;
       padding: unset;
   }
   .accordion-item{
    margin-left: 2%;
    margin-right: 2%;
   }

   /* .accordion-button{
       font-size: 1rem;
   } */
   .question-content{
       font-size: 1rem;
   }
  }

  @media (max-width: 280px) {
      .accordion-button{
          padding-left: 0.3rem;
      }
      .question-content{
        font-size: 0.8rem;
      }
  }
