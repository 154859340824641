.reg-container {
  /* background-image: url('../../../../src/assets/Mask\ Group\ 6.png'); */
  background-color: #191818;
  min-height: 100%;
  background-position: 40rem 0rem;
  background-repeat: no-repeat;
  position: relative;
}

.navbar-container {
  padding: 0rem 0rem 4rem 0rem;
  position: relative;
}

.half-black {
  background-image: url('../../../../src/assets/Mask\ Group\ 6.png');
  height: 100%;
  background-color: #191818;
  width: calc(50% + .6rem) !important;
  position: absolute !important;
  right: 0;
  background-position: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.register-page-container {
  margin: 0.5rem 0rem 0rem 0rem !important;
}

@media (max-width: 767.98px) {
  .half-black {
    visibility: hidden;
  }

  .navbar-container {
    padding: 0rem 0rem 1rem 0rem;
  }
}