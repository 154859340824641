.asc-textContainer {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  background: transparent
    linear-gradient(333deg, #ffffff 0%, #ffffffd2 48%, #ffffffa0 100%) 0% 0%
    no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 16px;

  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  height: 60%;
  justify-content: space-between;
}

.activeContainer {
  display: flex;
  align-items: center;
  margin-top: 35px;
  overflow-y: auto;
  overflow-x: hidden;
}

.activeItem {
  display: flex;
  align-items: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/28px var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/28px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.asc-active-now{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 32px/30px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}
.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    margin: 0;
  }
}
@media screen and (max-width: 579px) {
  .textContainer {
    text-align: center;
  }
}
@media screen and (max-width: 579px) {
  .activeContainer {
    justify-content: center;
  }
}
.asc-active-now-infobar{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 32px/30px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}