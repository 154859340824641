.moving-logo{
        width: 100%;
                padding: 40px 0 72px 0;
            background-color: black;
          
}
.sloganone-div {


    background-position: center;

    background-image: url("../../assets/Group 1168.svg");
        height: 2rem;
        max-width: 15rem !important;
  
    background-size: 100% 100%;
   

}
.slick-ss{
    overflow-x: hidden;
        width: 99vw !important;
}
.red-line{
    width: 100%;
     position: relative;
     top: -10rem;  
        transform: rotate(-5.588deg);
        flex-shrink: 0;
        background: #E60000;
   
}
.main-bg{
    background-color: black;
    height: 105%;
}
@media only screen and (max-width: 991.98px) {
    .red-line {
           
            top: 0;
           
    
        }
    .sloganone-div {height: 2rem;
        width: 7.5rem !important;}
}