.project-details-container {
  font: normal normal normal 1.25rem/1.4375rem Vodafone Rg;
}
.tags-input-container {
  display: flex;
  align-items: center;
  flex-flow: row wrap !important;
}
.container-center .idea-techs-tags,
.container-center .idea-types-tags {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.add-input-field {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.project-details {
  padding: 5%;
  padding-bottom: 3%;
}
.project-details-form .form-header {
  border: none;
  height: initial;
}
.project-details-form .form-header-text {
  color: var(--text-primary) !important;
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  margin: 0px;
  font-weight: bold;
  padding-bottom: 1.25rem;
}
.project-details-form .form-group:not(:first-of-type) {
  margin-top: 0px;
  margin-bottom: 0px;
}
.project-details-form .form-group:first-of-type {
  margin-top: 0rem;
  margin-bottom: 0px;
}
.project-details-form {
  min-height: calc(100vh - 10rem);
}
.project-details-form .proj-details-form-alignment {
  padding-left: 4rem !important;
}
.project-details-form .scrolled {
  height: 73vh;
  width: 100%;
}
.project-details-form .form-control {
  border-radius: 10px !important;
}
.project-details .project-form-label {
  font-size: 1.25rem;
  color: #4a4d4e;
  margin-bottom: unset;
}

.form-control {
  border-radius: 16px !important;
  border: 1px solid #bebebe !important;
  height: 48px !important;
}

.form-control,
.alert-danger {
  width: 100%;
}
.project-details-form .alert-danger {
  padding: 0.5rem 0rem 0rem 0rem !important;
  border: none !important;
  background: none !important;
  color: var(--text-secondary) !important;
}

.alert-danger.idea,
.alert-danger.email {
  width: 100%;
  margin-bottom: unset !important;
}

#ideaType {
  width: 12.875rem;
}
#ideaTech {
  width: 12.875rem;
}

.tag,.ReactTags__tag {
      background-color: #e60000;
        color: white;
        padding: 0.5rem;
        border-radius: 10px;
        margin: 0.5rem;
}
.content {
  text-align: left;
  letter-spacing: 0px;
  padding: 0px !important;
  height: initial !important;
  background: none;
}
.rm-lnk {
  background: none;
  padding: 0px !important;
  margin-left: 5px;
}

#ideaTech,
.add-tech-btn {
}
.form-footer {
  display: flex;
  justify-content: space-around;
}

.btn-secondary,
.btn-success {
  padding: 1% 5% 1% 5% !important;
}
.add-email {
  width: 12.875rem !important;
}
.add-type-btn,
.add-tech-btn {
  color: #e60000;
  font-size: 2rem;
  cursor: pointer;
  line-height: 1.3rem;
  height: 1.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.emails-hint {
  color: #4a4d4e;
  font-size: 1rem;
}

.add,
.minus {
  color: #e60000;
  background: unset;
  font-size: 2rem;
  border: unset;
}

.file-input-container-text {
  font-size: 1.5rem;
  letter-spacing: 0px;
  line-height: 1.8rem;
}

@media (max-width: 991.98px) {
  .project-details-form {
    padding: unset !important;
    margin: unset !important;
  }
  .project-details-form .proj-details-form-alignment {
    padding-left: 0rem !important;
  }
  .project-details-form .scrolled {
    padding: 0px !important;
    height: calc(73vh - 2.5rem);
  }
  .project-details-form .form-group.input-width-80 {
    width: 100% !important;
  }
  .project-details-form .form-group.input-width-80 > .col-sm-12 {
    padding-right: 0px;
  }

  .project-details-form label,
  .project-details-form .label-format {
    font-size: 1.12rem;
    line-height: 1.8rem;
    letter-spacing: 0px;
    margin: 0px;
  }
  .file-input-container-text {
    font-size: 1.125rem;
    letter-spacing: 0px;
    line-height: 1.3rem;
  }
  .emails-hint {
    font-size: 0.812rem;
    line-height: 0.937rem;
  }
  .project-details-form .form-group {
    margin-top: 1.5rem;
    margin-bottom: 0px;
  }
}

@media (max-width: 767.98px) {
  .project-details-form .form-header-text {
    text-align: center;
    font: bold 1.75rem/2.375rem Vodafone Rg;
    letter-spacing: 0.175rem;
  }

  .project-details-form .form-group .form-control {
    height: 2.5rem !important;
  }
}
.rti--tag{
  background-color: #e60000 !important;
  color: white;
}
.ReactTags__selected a.ReactTags__remove{
      background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: white;
}


.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  color: #4a4d4e;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
.full-name {
  display: flex;
  align-items: center;
}
.boolean-div{
  display: flex;
  flex-direction: row;
}