.borderBottom {
  border-bottom: 0.1rem solid #cccccc !important;
}

.white {
  color: #ffffff !important;
}
.white-1 {
  color: #cccccc !important;
}
.blue {
  color: #007c92 !important;
}
.red-1 {
  color: #e60000 !important;
}
.grey-1 {
  color: #999999 !important;
}
.grey-2 {
  color: #707070 !important;
}
.grey-3 {
  color: #4a4d4e !important;
}

.card {
  border: none !important;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff !important;
  border-bottom: none !important;
}

.card-body {
  height: auto;
  width: 96%;
  padding: 2rem 2rem 2rem 2rem;
  margin-left: 2rem;
  overflow: hidden;
  border: 1px solid #cccccc;
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 16px;
  opacity: 1;
}

.card-title {
  text-align: center;
  color: #4a4d4e;
  font: normal normal normal 20px/20px Vodafone Rg;
  letter-spacing: 0px;
  opacity: 1;
}
.card-text {
  text-align: end;
  color: #4a4d4e;
  font: normal normal normal 16px/18px Vodafone Rg;
  letter-spacing: 0px;
  opacity: 1;
}

.form-check-label {
  text-align: left;
  font: normal normal normal 16px/18px Vodafone Lt;
  letter-spacing: 0px;
  color: #4a4d4e;
}
.consent-wrapper {
  min-height: calc(100vh - 10rem);
}
.scrolled-consent {
  overflow-y: auto;
  height: 80vh;
  overflow-x: hidden;
  padding-left: 3rem;
}
.scrolled-consent::-webkit-scrollbar-thumb {
  background: darkgray;
}
.scrolled-consent::-webkit-scrollbar {
  width: 4px !important;
  background-color: #191818 !important;
  border-radius: 16px;
}
.consent-card-header-content {
  padding: 0 0 0 2rem;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  letter-spacing: 4px;
  color: #ffffff;
  text-transform: uppercase;
}
.consent-privacy {
  width: 90%;
  padding: 2rem 2rem 2rem 2rem;
  margin-left: 2rem;
  overflow: hidden;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 16px;
  opacity: 1;
}
.custom-padding-left {
  padding-left: 2rem;
}

@media screen and (max-width: 991.98px) {
  .scrolled-consent {
    padding-left: 1rem !important;
  }
}
@media screen and (max-width: 767.98px) {
  .scrolled-consent {
    height: 76vh;
  }
  .card-body,
  .consent-privacy {
    margin-left: unset;
  }
  .custom-padding-left {
    padding-left: 0rem;
  }
  .consent-card-header-content {
    padding: unset;
  }
}


