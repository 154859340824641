.background{
    background-color: #1F1F1F !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}
.line{
    width: 375px;
        height: 2px;
       margin-top: 1rem;
        background: #C92F23 0% 0% no-repeat padding-box;
        border-radius: 8px;
}
.card1{
    margin-top: 1rem;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 6px solid var(--unnamed-color-000000);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 6px solid #000000;
    border-radius: 6px;
    opacity: 1;
    width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
    
}.card2{
    margin-top: 1rem;
        background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
        border: 6px solid var(--unnamed-color-000000);
        background: #000000 0% 0% no-repeat padding-box;
        border: 6px solid #000000;
        border-radius: 6px;
        opacity: 1;
        width: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
}
.card2-boxes{
    width: 80%;
    min-height: 719px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 6px solid var(--unnamed-color-000000);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 6px solid #000000;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.card1-header{
    width: 100%;
        height: 94px;
        /* UI Properties */
        background: var(--unnamed-color-000000) 0% 0% no-repeat padding-box;
        background: #000000 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
}
.card1-header-title {font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-4);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 24px/30px Vodafone Rg;
    letter-spacing: 2.4px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;}
    .card1-header-subtitle{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-ffffff);
            text-align: center;
            font: normal normal normal 18px/40px Vodafone Lt;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
    }
    .card1-body{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
       
    }
         .card2-body {
             display: flex;
            width: 100%;
             align-items: center;
            
    
         }
    .card1-boxes{
       
        max-width: 278px;
        min-width: 278px;
            height: 184px;
            border: 3px solid var(--unnamed-color-000000);
            border: 3px solid #000000;
            opacity: 1;
            
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin: 1rem 1rem;

    }
    .card1-boxes-title{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 20px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
            color: var(--unnamed-color-000000);
            text-align: center;
            font: normal normal bold 20px/30px Vodafone Rg;
            letter-spacing: 2px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
    }
    .card1-boxes-img{
        width: 32px;
            height: 32px;
    }
    .card1-boxes-count{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 64px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
            color: var(--unnamed-color-000000);
            text-align: center;
            font: normal normal bold 64px/30px Vodafone Rg;
            letter-spacing: 6.4px;
            color: #000000;
            text-transform: uppercase;
            opacity: 1;
    }
.main-page{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}
.approved-ribon{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: center;
        font: normal normal normal 20px/40px Vodafone Lt;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        background: #A8B400 0% 0% no-repeat padding-box;
            opacity: 1;
            
            width: 80%;
}
.pending-ribon {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 20px/40px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
background: #00B0CA 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 80%;
}
.rejected-ribon {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 20px/40px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
background: #C92F23 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 80%;
}
.card2-boxes-title{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
        letter-spacing: var(--unnamed-character-spacing-2-4);
        color: var(--unnamed-color-000000);
        text-align: center;
        font: normal normal bold 24px/30px Vodafone Rg;
        letter-spacing: 2.4px;
        color: #000000;
        text-transform: uppercase;
        opacity: 1;
}
.button-style{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-rg);
        color: var(--unnamed-color-ffffff);
        text-align: center;
        font: normal normal bold 24px/32px Vodafone Rg;
        letter-spacing: 0.48px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
        background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
            background: #E60000 0% 0% no-repeat padding-box;
            border-radius: 6px;
            opacity: 1;
            border: none;
            padding: 0.5rem 1rem;
}
.chart{
 
    
    height: 380px !important;
    width: 100% !important;
}
.card3-body{
    min-height: 700px;
    display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem 0;
}