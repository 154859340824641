.loader-back {
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color:rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
}
