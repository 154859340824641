/* home page */

.removemargins {
  margin: 0 !important;
  padding: 0 !important;
}

.landing-page-container {}

.removescroll {
  margin-right: 0 !important;
  margin-left: 0 !important;

}

/*******************container********************/

.nav-container {
  margin-left: 7.3%;
  margin-right: 7.3%;
}

/*******************firs-section********************/

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  opacity: 0.6;
  -webkit-animation: toggleOpacity 1.2s 3s forwards;
}

/* Add some content at the bottom of the video/page */
.fisrt-section {
  /* background-image: url("../../assets/Hero_Image.png"); */

  z-index: 100;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.first-section-Content {
  display: flex;
  justify-content: center;
   height: 44vh;
  align-items: flex-end;
 
}

.firstsectionImg {
  width: 64.7%;
  height: 9.75rem;
  -webkit-animation: moveDown 2s ease-in-out;
  animation: moveDown 2s ease-in-out;
}

.second-section-Content {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  /* margin-top: 10.5vh !important; */
  margin-bottom: 2.5rem !important;
}

.register-border-line {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  top: 591px;
  left: 588px;
  width: 191px;
  height: 10px;
  margin-top: 0px !important;
  margin-bottom: 0.5rem;
}

.register-btn-home {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 23px/72px var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 23px/72px Vodafone Rg;
  letter-spacing: 2.3px;
  color: #FFFFFF;
  text-transform: uppercase;
  border: none;
  background-color: #e60000;
  text-transform: uppercase;
  opacity: 1;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  border: 3px solid var(--unnamed-color-e60000);
  background: #E60000 0% 0% no-repeat padding-box;

  border-radius: 16px;
  width: 16.625rem;
  height: 4.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.register-btn-home:hover {}

.font-color-home {
  color: white;
}

.rightArrow {
  position: absolute;
  z-index: 100000;
  right: 0rem;
  bottom: -3.5rem;
}

.leftArrow {
  position: absolute;
  z-index: 100000;
  left: -1rem;
  bottom: -3.5rem;
}

.fisrt-section .row {
  margin: 0 !important;
}

/*******************second-section********************/
.secondsection {
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 4rem 0 4rem;
}

.ninthsection {
  z-index: 50;
  width: 100%;
  height: 100%;

  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.section2-mainDev {
  width: 100%;
  justify-self: center;

  padding-top: 4.688rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.font-color-home-section2 {
  color: black;
}

.section2-headerText {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50) / var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-5);
  text-align: center;
  font: normal normal bold 3.125rem Vodafone Rg;
  letter-spacing: 5px;
  color: #1b1a1a;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 2rem 0;
}

.section9-headerText {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50) / var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-5);
  text-align: center;
  font: normal normal bold 3.125rem Vodafone Rg;
  letter-spacing: 5px;
  color: white;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 2rem;
}

.section2-redline {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 6.688rem;
  height: 3px;

  margin-bottom: 2rem;
}

.section2-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 2rem Vodafone Rg;
  line-height: 2.5rem;
  letter-spacing: 0px;
  color: #1b1a1a;
  opacity: 1;
  margin-bottom: 2.5rem;
}

.section9-text {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: 0px;

  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;
  padding: 1rem 0;
}

.section9-subheader {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: justify;
  letter-spacing: 0px;

  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF !important;
}

.prizes {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.prizes-sub {
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  opacity: 1;

  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 41rem ;

}

.prizes2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0.687rem;
}

.prize-image {
  margin-bottom: 1rem;
  width: 5rem;
}

.pitch-image {
  margin-bottom: 2rem;
  /* width: 20rem; */
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  /* border: 1px #ffffff solid; */
  height: 20rem;
  max-height: 16.125rem;
  inline-size: -webkit-fill-available;
}

.pitch2-image {
  margin-bottom: 1rem;
  width: 5rem;
}

.prize-redline {
  top: 1310px;
  left: 211px;
  width: 107px;
  height: 3px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  /* UI Properties */
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;

  margin-bottom: 2rem;
}

.prizeText1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24) / var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  text-align: center;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #1b1a1a;
  text-transform: uppercase;
  opacity: 1;
}

.pitchText1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 32px/40px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.pitchText1-2 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-4);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 40px/40px Vodafone Rg;
  letter-spacing: 4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  min-height: 4.5rem;
  max-height: 4.5rem;
}

.prizeText2 {
  letter-spacing: var(--unnamed-character-spacing-4);
  font: normal normal normal 2.5rem Vodafone Rg;
  text-align: center;
  letter-spacing: 4px;
  color: #1b1a1a;
  text-transform: uppercase;
  opacity: 1;
}

.ptitchText2 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
      /* max-width: 20.938rem; */
        text-align: left;
        padding: 0 1rem;
}

.ptitchText2-2 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.prizesSection {
  display: flex !important;
  /* flex-flow: row wrap; */
  justify-content: space-evenly;
  align-items: baseline;
  margin: 2rem 0;
  /* margin-top: 0px; */
  width: 100%;
}

/*******************third-section********************/

.Thirdsection {
  z-index: 1;
  width: 100%;
  background-image: url("../../assets/section2-home-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-bottom: 6.87rem;
  background-color: var(--background);
  border: 0px;
}

.section3-mainDev {

  justify-self: center;
  /* margin-left: 4rem; */
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* margin-bottom: 3rem; */
  margin: 0 6.438rem;
}

.font-color-home-section2 {
  color: black;
}

.section3-headerText {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50) / var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-5);
  text-align: center;
  font: normal normal bold 3.125rem Vodafone Rg;
  letter-spacing: 0.313rem;
  color: white;
  text-transform: uppercase;
  opacity: 1;
  line-height: 6.25rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.section3-redline {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  top: 591px;
  left: 588px;
  width: 191px;
  height: 3px;
  margin-bottom: 2rem;
}

.section3-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 32px/40px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  max-width: 49.563rem;
}

/*******************fourth-section********************/

.Fourthsection {
  width: 100%;
  height: 100%;
  background-color: #191818;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  padding-top: 1.4rem;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 4.4rem;
}

.FourthSection-MainDEv {
  padding: 0 6.838rem;
  align-items: center;
  /* overflow-x: hidden; */
  width: 100%;
}

.fourthsectionTitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-40) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-4);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 40px/40px Vodafone Rg;
  letter-spacing: 4px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 2rem;
  line-height: 2.5rem;
}

.fourthsectionImg {
  width: 35.938rem;
  height: 30.688rem;
  inline-size: -webkit-fill-available;

}

.fourthsecimg {
  padding-left: 2rem;
}

.fourthsectionContent {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-bottom: 2rem;
  max-width: 80%;
}

.fourthsectionContent-bold {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-lt);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 2rem Vodafone Lt;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-bottom: 2rem;
  max-width: 80%;
}

.fourthsectionLine {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  top: 591px;
  left: 588px;
  width: 191px;
  height: 3px;

  margin-bottom: 2rem;
}

/************************Fifth-section*************/
.welcome-msg {
  background-image: url("../../assets/welcome.gif");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 20vh;
}

.Fifthsection {
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: #191818;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 6.25rem;
  padding-bottom: 3.5rem;
  background-size: cover;
  background-repeat: no-repeat;
}

.timelinesection {
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: #191818;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

h1.entry-title {
  font-size: 3.125rem;
  letter-spacing: 0.3125rem;
  line-height: 6.25rem;
  font-weight: bold;
  margin-bottom: 0px;
}

.timeLineContainer {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.sectionDeadLine {
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: #191818;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.sectionDeadlineDateBox {

  opacity: 1;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem;
  margin-bottom: 0px;
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  border-radius: 16px;
  opacity: 1;
}

.countdown-number-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 46px / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 46px/40px Vodafone Rg;
  letter-spacing: 4.6px;
  color: #ffffff;
  text-transform: uppercase;
}

.countdown-number-title-small {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 16px/15px var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 16px/15px Vodafone Rg;
  letter-spacing: 1.6px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.countdown-number-sub {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-8);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 28px/40px Vodafone Rg;
  letter-spacing: 2.8px;
  color: #ffffff;
  text-transform: uppercase;
}

.countdown-number-sub-small {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/15px var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 12px/15px Vodafone Rg;
  letter-spacing: 1.2px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.sectionDeadline-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-50) / var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-5);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 3.125rem/6.25rem Vodafone Rg;
  letter-spacing: 5px;
  color: #ffffff;

  text-transform: uppercase;
  opacity: 1;
  left: 30%;
  margin-bottom: 0px;
}

.sectionDeadline-title-small {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/15px var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 14px/15px Vodafone Rg;
  letter-spacing: 0.7px;
  color: #FFFFFF;
  opacity: 1;
}

.sectionDeadline-titleBG {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 133px/238px var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 133px/238px Vodafone Rg;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.02;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 30%;
  bottom: 40%;
  z-index: 5555554;
}

.sectionDeadline-maindev {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 2rem;
  flex-direction: column;
}

.sectionDeadline-redline {
  justify-self: center;
  background: var(--unnamed-color-e60000) 0% 0% no-repeat padding-box;
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;

  width: 12rem;
  height: 3px;

  margin-bottom: 1.625rem;
}

.sectionDeadline-text {
  font-size: 2rem;
  line-height: 2.25rem;
  letter-spacing: 0.1rem;
  color: var(--text-primary);
  margin-bottom: 1.625rem;
}

.sectionDeadline-text-small {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/19px var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 18px/19px Vodafone Rg;
  letter-spacing: 0.9px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 0.825rem;
}

.sectionDeadlineDateBox {
  width: 9.4rem;
  height: 10rem;
  margin-top: 0.5px;
}

.sectionDeadlineDateBox-small {
  width: 60px;
  height: 64px;
  /* UI Properties */
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  opacity: 1;
}

.DeadlineBoxesContainer {
  margin-bottom: 2.5rem;
}

.DeadlineBoxesContainer-small {
  margin-bottom: 0.5rem;
  max-height: 5rem;
}

.timer-column {
  height: 4.563rem;
  justify-content: space-evenly;
}

.timer-circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: transparent linear-gradient(180deg, #730000 0%, var(--unnamed-color-e60000) 100%) 0% 0% no-repeat padding-box;
  /* background: transparent linear-gradient(180deg, #730000 0%, #e60000 100%) 0% 0% no-repeat padding-box; */
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #FFFFFF;
  opacity: 1;
}

.register-btn-text {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-32)/var(--unnamed-line-spacing-100) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-3-2);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal normal 32px/100px Vodafone Rg;
  letter-spacing: 3.2px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.timer-circle-small {
  width: 7px;
  height: 7px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  margin: 0 0.5rem;
}

.Vois_logo {
  width: 8.063rem;
  height: 2rem;
  background: url('../../assets/_vois_white.png');
  position: absolute;
  left: 6.25rem;
  top: 2.375rem;
  display: inline-block;
}

/*******************animations********************/
@-webkit-keyframes moveUp {
  from {
    transform: translateY(50rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@keyframes moveUp {
  from {
    transform: translateY(50rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@-webkit-keyframes moveDown {
  from {
    transform: translateY(-25rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@keyframes moveDown {
  from {
    transform: translateY(-25rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@-webkit-keyframes toggleOpacity {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.7;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes toggleOpacity {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.7;
  }

  75% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

/*******************media queries********************/
/*****************tablet-size***************/


.link,
.link:hover {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: 0px;

  font: normal normal normal 32px/40px Vodafone Lt;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  cursor: pointer;
}

.floating-btn {
  position: fixed;
  right: 6.25rem;
  z-index: 5000;
  border: none;
  float: right;
  width: 3.25rem;
  height: 3.25rem;
  top: 1.75rem;

}

.close-btn {
  margin-right: 6.25rem;
  margin-top: 1.75rem;

  border: none;
  float: right;

  width: 3.25rem;
  height: 3.25rem;
  align-items: center;
}

.pop-over-border {
  border-right: 1px white solid;
}

.pop-over-section {
  display: flex;
  justify-content: space-around;
  padding-left: 7.5rem !important;
  flex-direction: column;
}

.pop-over-section2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.pop-over-text-align {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 45vh;
}

.pop-over-text-align-left {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 80vh;
}

.list-words-text,
.list-words-text:hover {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 30px/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal bold 30px/40px Vodafone Rg;
  letter-spacing: 3px;
  color: #FFFFFF;
  opacity: 1;
  text-decoration: none;
}

.sections-text,
.sections-text:hover {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/40px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  text-decoration: none;
}

.second-section {
  display: flex;
  justify-content: center;
  max-height: 60vh;
}

#whatinit {
  padding: 0 6.25rem;
}

.fourthsectionTextContainer-right {

  /* height: 30.688rem; */
  padding-left: 3rem !important;
}

@media only screen and (max-width: 991.98px) {
  .pop-over-section {
    padding-left: 0.5rem;
  }

  .mobile-logo {
    background: url("../../assets/logo.svg");
    height: 1rem;
    width: 12.75rem;
    top: 2.3rem !important;
    background-repeat: no-repeat;
    position: absolute;
    left: 1.5rem;
  }

  .Vois_logo {
    width: 7.75rem;
    height: 1.875rem;
    top: 1.031rem;
    display: none !important;
  }

  .close-btn,
  .floating-btn {

    padding: 0 !important;
    top: 0.65rem !important;
    width: 3rem !important;

  }

  .fisrt-section {
    /* background-image: url("../../assets/mobilebg.png"); */

  }

  .first-section-Content {

    height: 30vh;
  }

  .close-btn {
    width: 5rem;
  }

  .mobile-logo {
    background: url("../../assets/logo.svg");
    height: 1rem;
    width: 12.75rem;
    top: 2.3rem !important;
    background-repeat: no-repeat;
    position: absolute;
    left: 1.5rem;
  }

  .Vois_logo {
    width: 7.75rem;
    height: 1.875rem;
    top: 1.031rem;
    display: none !important;
  }

  .close-btn,
  .floating-btn {

    padding: 0 !important;
    top: 0.65rem !important;
    width: 3rem !important;

  }

  .fisrt-section {
    /* background-image: url("../../assets/mobilebg.png"); */

    z-index: 100;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
  }

  .FourthSection-MainDEv {
    padding: 0 2.5rem !important;
  }

  .prizesSection>div {
    padding: 0 !important;
    margin-top: 1.5rem;
  }

  .section9-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .pitch-image {
    height: unset;
  }

  .prizes-sub {
    min-height: 34rem;
  }

  .ptitchText2 {
    padding: 1rem;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .register-btn-text {
    font: normal normal normal 24px/26px Vodafone Rg;
  }

  .fourthsectionTextContainer-right {
    padding: 0 !important;
  }

  .nav-container {
    margin: 0px;
  }

  .register-btn-home {
    width: 13.375rem;
    height: 3rem;
    font-size: 1.25rem;
    letter-spacing: 0.125rem;
    line-height: 3.938rem;
    margin-left: 2.5rem;
  }

  .second-section-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 50vh; */
    -webkit-animation: moveUp 3s ease-in-out;
    animation: moveUp 3s ease-in-out;
    width: 100%;
    padding-top: 17rem !important;
  }

  .welcome-msg {
    min-height: 10vh;
    background-image: url("../../assets/welcome_mobile.gif");
  }

  .rightArrow,
  .leftArrow {
    display: none;
  }

  .firstsectionImg {
    width: 96.3%;
  }

  .section2-mainDev {
    margin-left: 0 !important;
    padding-top: 0px !important;
  }

  .section2-headerText {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    text-align: center;
    font: normal normal bold 28px/38px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #191818;
    text-transform: uppercase;
    opacity: 1;
  }

  .section9-headerText {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/38px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
  }

  .section9-subheader {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);

    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .section2-redline.section2-header-line {
    width: 11.938rem;
  }

  .section2-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .Thirdsection {
    background-image: url("../../assets/section2-home-bg_mobile.png");
  }

  h1.entry-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/38px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 2rem;
  }

  .Fifthsection {
    padding: 0;

  }

  .prizeText2 {
    font-size: 1.75rem;
  }

  .section2-mainDev {
    width: 100%;
    justify-self: center;
    margin-left: 0 !important;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .section2-redline {
    width: 6.688rem;
  }

  .section3-mainDev {
    margin: 0px !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 8rem 2rem;
  }

  .section3-headerText {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/38px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 0px;
    margin-bottom: 2rem;
  }

  .section3-redline {
    margin-bottom: 1.8rem;
  }

  .section3-text {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 0px;
  }

  .Fourthsection {
    padding-top: 2.5rem;
  }

  .FourthSection-MainDEv.pt-5 {
    padding-top: 0px !important;
    margin: 0;
  }

  .secondsection {
    background-color: #fff;
    padding: 0;
  }

  .FourthSection-MainDEv>* {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    margin-bottom: 1.2rem;
    padding: 0 !important;
  }

  .fourthsectionTitle {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-28)/var(--unnamed-line-spacing-38) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal bold 28px/38px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 1.2rem;
  }

  .fourthsectionImg {
    width: 100%;
    height: fit-content;
  }

  .fourthsecimg {
    padding: unset;
  }

  .fourthsectionContent {
    padding-bottom: 0rem;
    max-width: 100%;
    text-align: start;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-32) var(--unnamed-font-family-vodafone-lt);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal normal 24px/32px Vodafone Lt;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .fourthsectionLine {
    width: 191px;
    margin-bottom: 1.2rem;
  }

  .countdown-number-title {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 46px / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 46px/40px Vodafone Rg;
    letter-spacing: 4.6px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .countdown-number-sub {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-28) / var(--unnamed-line-spacing-40) var(--unnamed-font-family-vodafone-rg);
    letter-spacing: var(--unnamed-character-spacing-2-8);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal normal 28px/40px Vodafone Rg;
    letter-spacing: 2.8px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .sectionDeadline-title {
    font-size: 1.75rem;
    letter-spacing: 0.175rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }

  .sectionDeadline-maindev {}

  .sectionDeadline-redline {
    margin-bottom: 1.2rem;
  }

  .sectionDeadline-text {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .sectionDeadlineDateBox {
    min-width: 4.25rem;
    height: 4.563rem;
    margin-top: 0px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .DeadlineBoxesContainer {
    margin-bottom: 1.625rem;
  }

  .countdown-number-title {
    font-size: 1.3rem;
    letter-spacing: 0.13rem;
    line-height: 1.125rem;
  }

  .countdown-number-title-small {
    font-size: 0.9rem;
    letter-spacing: 0.13rem;
    line-height: 0.8rem;
  }

  .countdown-number-sub {
    font-size: 0.8rem;
    letter-spacing: 0.08rem;
    line-height: 1.063rem;
  }

  .countdown-number-sub-small {
    font-size: 0.5rem;
    letter-spacing: 0.08rem;
    line-height: 0.5rem;
  }

  .timer-circle {
    width: 0.5rem;
    height: 0.5rem;
  }

  .timer-circle-small {
    width: 0.3rem;
    height: 0.3rem;
  }

  .prizeText2 {
    font-weight: bold;
  }

  .pop-over-section {
    padding-left: 0.5rem !important;
  }

}

/*****************mobile-size***************/
@media only screen and (max-width: 767.98px) {
  .prizes {
    padding: 0 !important;
  }

  .timer-circle-small {
    margin: 0 0.15rem !important;
  }

  .day>p {
    margin-bottom: 0.25rem !important;
  }

  .pop-over-section {
    padding-left: 0.75rem !important;
  }

  .sectionDeadlineDateBox-small {
    background: transparent !important;
    color: white;
    border: 1px white solid;
    border-radius: 18px;
    padding: 1rem;
  }

  .countdown-number-title-small {
    font-size: 1rem;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
  }

  .fisrt-section {
    /* background-image: url("../../assets/mobilebg.png"); */

  }

  .first-section-Content {

    height: 82vh;
  }

  .pop-over-text-align {
    margin: 2rem 0;
    border: none;
  }

  .DeadlineBoxesContainer-small {
    margin: 0.25rem 0;
  }

  .pop-over-text-align-left {
    margin: 2rem 0;

  }

  .second-section {
    padding: 2rem 0;
  }

  .mobile-logo {
    background: url("../../assets/logo.svg");
    height: 1rem;
    width: 12.75rem;
    top: 2.3rem !important;
    background-repeat: no-repeat;
    position: absolute;
    left: 2.5rem;
  }

  .Vois_logo {
    width: 7.75rem;
    height: 1.875rem;
    top: 1.031rem;
    display: none !important;
  }

  .close-btn,
  .floating-btn {
    padding: 0 !important;
    top: 0.65rem !important;
    width: 2.25rem !important;
    margin-right: 1rem !important;
    margin-top: 1rem !important;
    right: 3rem !important;
  }

  .fourthsectionTextContainer {
    order: 2 !important;
  }

  .Thirdsection {
    padding-bottom: 0px;
  }

  .Fourthsection {
    padding-bottom: 0px;
  }

  .timeLineContainer {
    padding-top: 2.5rem;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .Fifthsection {

    background-position-x: center;
  }

  .close-btn {
    margin-right: 3rem !important;
  }
}

@media only screen and (min-height: 600px)and (max-width: 376px) {
  .second-section-Content {
    padding-top: 12.5rem !important;
  }
}

@media only screen and (min-height: 700px)and (max-width: 376px) {
  .second-section-Content {
    padding-top: 14.5rem !important;
  }
}

@media only screen and (min-height: 800px)and (max-width: 450px) {
  .second-section-Content {
    padding-top: 18.5rem !important;
  }
}

.slick-slider {
  width: 82vw !important;

}

.speacker-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.speacker-div {
  justify-content: center;
  align-items: center;
  display: flex !important;
  flex-direction: column;

}

.speacker-name {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-2-4);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 24px/30px Vodafone Rg;
  letter-spacing: 2.4px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.spaecker-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;


}

.speacker-region {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-vodafone-rg);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;
  font: normal normal normal 18px/21px Vodafone Rg;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.speacker-details {
  margin: 0.5rem 0rem 1rem 0rem;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slick-dots li.slick-active button:before {
  color: #FFFFFF;
  font-size: 24px;
}

.slick-dots li button:before {
  color: #626262;
  font-size: 16px;
}

.slick-next:before,
[dir=rtl] .slick-prev:before {
  content: '>';
  font-size: 29px;
  color: white;
  opacity: 1;
}

.slick-prev:before {
  content: '<';
  font-size: 29px;
  color: white;
  opacity: 1;
  /* background: transparent; */
}