.paging{
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-radius: 0px 0px 10px 10px;
}
.paging .paging-item.next{
  margin-inline-start: 1rem;
  color: black;
}
.paging .paging-item.previous{
  margin-inline-start: unset;
  color: black;
}
.paging .paging-item{
  margin-inline-start: 1rem;
  color: #191818;
  cursor: pointer;
}
.paging .paging-item.active{
  background-color: black;
  color: white;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 5px;
}

.filtraion-panel-admin-container{
  height: 100%;
  width: 100%;
  background-color:#191818;
}

.filtraion-panel-admin-container .coaches-pageContainer{
  background: unset;
  background-color:#191818;
}

.search-sort-container{
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-sort-container .sort-btn{
  background-color: unset;
  border: unset;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 0.3rem 1rem 0.3rem 1rem;
}

.search-container{
  border: 2px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

.search-container .search-input{
  background: unset;
  border: unset;
  padding-left: 1rem;
  color: white;
}

.fa-eye{
  color: #E60000;
}

.fa-search-panel{
  background-color: white;
  padding: 0.5rem;
  border-radius: 0px 7px 7px 0px;
}

.filtration-panel-admin-header-Card {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  min-height: 5rem;
  margin-top: 0.5rem;
}
.filtration-panel-admin-table-card-header {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 1rem;
  border-bottom: 1px solid #d5d5d5;
  opacity: 1;
}
.filtration-panel-admin-table-width {
  margin: 0.5rem 1rem 2rem 0rem; 
  min-height: 75vh;
}

.filtration-panel-admin-btn-selcted {
  background: #e60000 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  color: #ebebeb;
  padding: 0.25rem 0.25rem;
  margin-right: 0.25rem;
  width: 8rem;
  margin-bottom: 0.5rem;
  border: none !important;
}
.filtration-panel-admin-btn-unselcted {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  color: #e60000;
  padding: 0.25rem 0.25rem;
  margin-right: 0.25rem;
  width: 8rem;
  margin-bottom: 0.5rem;
  border: none !important;
}

.filtration-panel-admin-table-width thead th{
  border-top: unset;
  border-bottom: 2px solid #191818 !important;
  border-right: 2px solid #191818 !important;
  font-weight: bold;
}

.filtration-panel-admin-table-width tbody tr td {
  border-bottom: 2px solid #191818;
  border-right: 2px solid #191818;
}

.filtration-panel-admin-table-width thead th,
.filtration-panel-admin-table-width tbody tr td {
  color: #4A4D4E;
  background-color: white;
  font-size: 1.125rem;
}

.filtration-panel-admin-table-width thead th:first-child{
  border-radius: 10px 0px 0px 0px;
}

.filtration-panel-admin-table-width thead th:last-child{
  border-radius: 0px 10px 0px 0px;
}

.pagination {
  display: flex;
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination li,
.pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  color: #e60000;
  margin-left: 10px;
}

.pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination li a {
  font-weight: bold;
}

.pagination li.active a {
  color: #fff;
  background: #e60000;
}

.pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.filtration-panel-group-btn-dev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 81.3%;
  left: 8.7%;
  background-color: #191818;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid white;
}

.filtration-panel-group-btn-dev .dev-label {
  color: white;
  font-size: 1.25rem;
  margin-inline-start: 1rem;
}
.tableFixHead {
  overflow: auto;
  height: 100vh;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.add-group-btn {
  border: 1px solid var(--unnamed-color-ffffff);
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  padding: 0.5rem;
  border-radius: 16px;
  opacity: 1;
}
