.filtration-panel-group-card {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1%;
}
