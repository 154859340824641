.asc-mosaic-wall-images {
  width: 17rem;
  height: 15rem;
  margin: 0.25rem 1.25rem;
  border-radius: 16px;
  opacity: 1;
}
.asc-mosaic-background {
  margin: 2% 0.75%;

  height: fit-content;
  background: transparent
    linear-gradient(309deg, #ffffff 0%, #ffffffd2 48%, #ffffffa0 100%) 0% 0%
    no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 16px;
  opacity: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}
